@import "src/colors";

.profile-addresses {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 80px;
    background-color: $light;

    h1 {
        margin-top: 32px;
    }

    > .addresses {
        margin-top: 48px;

        .address-form,
        .address-dropdown {
            margin-top: 16px;
        }

        > .add {
            display: flex;
            align-items: center;
            margin-top: 24px;

            .plus {
                width: 36px;
                height: 36px;
            }

            a {
                margin-left: 8px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}
