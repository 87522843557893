@import "src/colors";
@import "src/fonts";

.profile {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 80px;

    .links {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        gap: 10px 14px;

        .caret-link {
            flex-grow: 1;
        }
    }

    > .filters {
        margin-top: 28px;

        > .inputs {
            position: absolute;
            background: white;
            border-radius: 20px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            padding: 20px;
            margin-top: 10px;
            width: 330px;

            > :not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    > .form {
        display: flex;
        flex-direction: column;
        margin-top: 76px;
        padding: 52px 55px 48px;
        border-radius: 20px;
        background-color: $dark;

        section {
            &:not(:first-child) {
                margin-top: 80px;
            }

            > .title {
                margin-bottom: 32px;

                h2 {
                    color: #ffffff;
                    font-family: Rota-BoldItalic;
                    font-size: 26px;
                    font-style: italic;
                    font-weight: bold;
                    letter-spacing: 1px;
                    line-height: 28px;
                }
            }

            .inputs {
                margin: 24px -12px -12px;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;

                > div {
                    width: calc(50% - 24px);
                    margin: 12px;
                }

                .select {
                    align-self: stretch;
                }
            }
        }

        .submit-block {
            margin-top: 48px;
            align-self: flex-start;

            .error {
                margin-top: 16px;
                color: red;
            }
        }

        .note {
            margin-top: 32px;
            color: #ffffff;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 16px;
        }
    }

    @media screen and (max-width: 1080px) {
        padding-left: 32px;
        padding-right: 32px;

        .links {
            flex-direction: column;
            align-items: flex-start;

            .caret-link {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;

        h1,
        .links {
            padding-left: 32px;
            padding-right: 32px;
        }

        h1 {
            font-size: 32px;
            line-height: 32px;
        }

        > .form {
            padding: 52px 32px 48px;
            border-radius: 0;

            section {
                .inputs {
                    > div {
                        width: 100%;
                        margin: 12px;
                    }
                }
            }
        }
    }
}
