@import "src/colors";

.download-link {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.has-label {
        align-self: flex-start;
        height: 44px;
        padding: 0 16px 0 8px;
        border: 1px solid $dark;
        border-radius: 22px;

        &:hover {
            opacity: 0.5;
        }
    }
}
