@import "src/colors";
@import "src/fonts";

#news-page {
    background-color: $light;
    padding: 32px 32px;

    .news-title {
        color: $dark;
        font-family: $bold;
        font-size: 48px;
        letter-spacing: 0;
        line-height: 48px;
        margin-top: 36px;
        margin-bottom: 26px;
    }

    .news-date {
        color: $primary;
        font-family: $extra-bold;
        font-size: 12px;
        letter-spacing: 1.5px;
        line-height: 14px;
    }

    .news-intro {
        margin-top: 44px;
        color: $dark;
        font-family: $regular;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 22px;
    }

    .news-content {
        margin-top: 80px;

        h1 {
            color: $dark;
            font-family: $bold;
            font-size: 36px;
            letter-spacing: 0;
            line-height: 38px;
            margin-top: 10px;
            margin-bottom: 32px;
        }

        p {
            color: $dark;
            font-family: $regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 22px;
        }

        img {
            width: 100vw;
            margin-left: calc((100vw - 100%) / -2);
        }

        p,
        img {
            margin-bottom: 30px;
        }
    }

    .news-footer {
        margin-top: 40px;

        h3 {
            margin-bottom: 8px;
        }

        a {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: $primary;
            color: $light;
            padding: 8px;
        }
    }

    .latest-news-title {
        color: $dark;
        font-family: $bold;
        font-size: 36px;
        letter-spacing: 0;
        line-height: 38px;
        text-align: center;
        padding-top: 60px;
        margin-bottom: 72px;
    }

    .lastest-news-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 48px;

        .news-card {
            max-width: calc(100% / 3 - 48px / 3);
        }

        @media screen and (max-width: 1024px) {
            .news-card {
                max-width: 100%;
            }
        }
    }

    @media screen and (min-width: 769px) {
        & {
            padding: 32px 72px;
        }

        .news-title,
        .news-intro,
        .news-content h1,
        .news-content p {
            max-width: 78%;
        }

        .news-content p,
        .news-content img {
            margin-bottom: 60px;
        }
    }
}
