@import "src/colors";
@import "src/fonts";

#mydata {
    @media screen and (max-width: 1080px) {
        padding-bottom: 80px;
    }

    #chartPage {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 34px;
        padding: 20px;
        margin-bottom: 16px;

        @media screen and (max-width: 1080px) {
            flex-direction: column;
        }

        #sideData {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            margin-left: 30px;
            width: 300px;
            flex-shrink: 0;

            @media screen and (max-width: 1080px) {
                margin: 0;
            }

            #totalOrders {
                font-family: "Rota-Regular";
                font-size: 16px;
                letter-spacing: 1px;
                line-height: 22px;
            }

            #total {
                font-family: "Rota-Bold";
                font-size: 48px;
                letter-spacing: 0;
                line-height: 48px;
                font-weight: bold;
                color: $secondary;
                margin-top: 8px;
            }

            #history {
                margin-top: 32px;
            }
        }
    }

    #forecasts {
        display: flex;
        align-items: flex-start;
        margin-top: 32px;
        padding: 0;

        @media screen and (max-width: 1080px) {
            padding: 0 32px;
        }

        > h2 {
            font-family: "Rota-Bold";
            font-size: 36px;
            letter-spacing: 0;
            line-height: 38px;
            font-weight: bold;
        }

        > .link-button {
            margin-top: 32px;
        }
    }

    #recentForecasts {
        display: flex;
        flex-wrap: wrap;
        margin: 16px -32px -16px;

        .recentForecast {
            max-width: 300px;
            margin: 16px 32px;
        }
    }
}
