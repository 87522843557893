@import "src/colors";
@import "src/fonts";

#posAndPopDetails {
    background-color: $light;

    .content {
        display: flex;
        flex-direction: column;

        .page-title {
            margin-top: 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h1 {
                font-family: Rota-Bold;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 38px;
                display: flex;
            }

            .dropdown-input {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100px;

                .input {
                    .label {
                        width: 100%;
                        justify-content: space-between;

                        span {
                        }
                    }
                }
            }
        }

        .introduction {
            margin-top: 30px;
            font-family: Rota-Regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 22px;
        }

        .download-button {
            margin-top: 50px;
            align-self: flex-start;

            .button {
                align-self: flex-start;
            }
        }

        .cover-image {
            margin-top: 78px;
        }

        .text-content {
            margin-top: 80px;
            font-family: Rota-Regular;
        }

        .related-products {
            margin-top: 120px;

            h2 {
                font-family: Rota-Bold;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 38px;
            }

            .items {
                margin-top: 80px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;

                .item {
                    margin-bottom: 60px;
                }
            }
        }
    }
}
