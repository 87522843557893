.password-input {
    position: relative;

    .toggle {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
    }
}
