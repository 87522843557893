@import "src/colors";
@import "src/fonts";

.events-card {
    background-color: white;
    border-radius: 20px;
    margin-top: 24px;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    .content {
        padding: 0 37px 40px;
    }

    .wave-container {
        position: relative;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 1200px) {
            height: 80px;
        }

        .header-wave {
            border-radius: 12px 12px 0px 0px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 0;
        }
    }

    .events-card-date-place {
        color: $white;
        font-family: $bold;
        font-size: 16px;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 20px;
        text-transform: uppercase;
        margin-top: 21px;
        z-index: 1;
    }

    .events-card-title {
        color: $dark;
        font-family: $extra-bold;
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 22px;
        margin-top: 8px;
        margin-bottom: 16px;
    }

    .events-card-description {
        color: $dark;
        font-family: $regular;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .read-events-link {
        display: inline-block;
        color: $primary;
        font-family: $extra-bold;
        font-size: 12px;
        letter-spacing: 1.5px;
        line-height: 14px;
        text-transform: uppercase;
        padding-bottom: 3px;
        margin-top: 20px;
        border-bottom: 2px solid $primary;
        cursor: pointer;
        transition: padding-right 0.2s ease-in-out;
    }

    .read-events-link:hover {
        padding-right: 4px;
    }

    @media screen and (min-width: 769px) {
        width: calc(50% - 12px);
    }

    @media screen and (min-width: 1025px) {
        width: calc(33% - 12px);
    }
}
