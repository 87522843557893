@import "src/colors";
@import "src/fonts";

#womanCareAcademy {
    background-color: $light;

    .content {
        display: flex;
        flex-direction: column;
        font-family: Rota-Regular;

        .items {
            .wcacademy-slider {
                margin: 35px 72px 80px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .content {
            .items {
                .wcacademy-slider {
                    margin: 35px 0px 80px;
                }
            }
        }
    }
}
