@import "src/colors";
@import "src/fonts";

#reportLawChange {
    h1 {
        margin-top: 36px;
    }

    #description {
        margin-top: 24px;
    }

    .links {
        margin-top: 60px;
    }

    > .form {
        section {
            .inputs {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;

                > #first-row {
                    margin-bottom: 24px;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;

                    > .dropdown-input {
                        flex: 1;
                        margin-right: 10px;
                        border-bottom: 1px solid white;

                        .input {
                            flex-direction: column;

                            .title {
                                text-transform: uppercase;
                                font-family: Rota-Bold;
                                font-size: 12px;
                                font-weight: bold;
                                letter-spacing: 2.4px;
                                line-height: 16px;
                            }

                            .label {
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                    }

                    > .text-input {
                        flex: 1;
                    }
                }

                > .text-area-container {
                    width: 100%;
                }

                > .file-input {
                    align-self: flex-start;
                    margin-bottom: 38px;
                }

                .check-input {
                    align-self: flex-start;
                }
            }
        }
    }

    .modal {
        padding: 32px;
    }

    @media screen and (max-width: 768px) {
        .back-link {
            padding-left: 32px;
            padding-right: 32px;
        }

        #description {
            padding-left: 32px;
            padding-right: 32px;
        }

        > .form {
            section {
                .inputs {
                    > #first-row {
                        flex-direction: column;

                        > .dropdown-input {
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }
    }
}
