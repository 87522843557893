@import "src/colors.scss";

.products-slider {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 100px 0 70px 72px;
    background-color: $light;

    .products-slider-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .title {
            font-family: Rota-Bold;
            font-size: 48px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 48px;
        }

        @media screen and (max-width: 768px) {
            .title {
                font-size: 36px;
                line-height: 36px;
            }
        }

        .arrows {
            top: 100px;
            right: 0;
            display: flex;

            .arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 50px;
                border: 1px solid $dark;
                border-radius: 50%;

                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }

        @media screen and (max-width: 1080px) {
            .arrows {
                position: static;
                margin-top: 55px;
            }
        }
    }

    .see-all {
        margin-top: 30px;
    }

    .slider-container {
        align-self: stretch;
        display: flex;
        margin-top: 55px;
        overflow: hidden;

        .slider {
            display: flex;
        }
    }
}
