@import "src/colors";

.label-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px;

    > .title {
        font-family: Rota-Bold;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 38px;
        text-align: center;
    }

    > .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        width: 80%;
        padding: 54px 32px 60px;
        background-color: $secondary;
        border-radius: 20px;

        .code {
            font-family: Rota-Bold;
            font-size: 36px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
            text-align: center;
            color: #ffffff;
        }

        a {
            margin-top: 28px;
        }
    }

    .message {
        margin-top: 39px;
        font-family: Rota-ExtraBold;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 20px;
        text-align: center;
    }

    .controls {
        display: flex;
        margin-top: 41px;

        > * {
            &:not(:first-child) {
                margin-left: 24px;
            }
        }
    }

    @media screen and (max-width: 1080px) {
        > .label {
            width: 100%;
        }
        
        .controls {
            flex-direction: column;

            > * {
                &:not(:first-child) {
                    margin-left: 0;
                    margin-top: 24px;
                }
            }
        }
    }
}
