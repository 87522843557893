@import "src/colors";
@import "src/fonts";

.address-modal {
    display: flex;
    flex-direction: column;
    padding: 48px 64px;

    > .address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;

        &:not(:first-child) {
            border-top: 1px solid #e5e5e5;
        }

        > .data {
            display: flex;
            flex-direction: column;
            margin-right: 10px;

            > .name {
                font-family: $bold;
            }
        }
    }

    .controls {
        align-self: center;
        display: flex;
        flex-wrap: wrap;
        margin: 16px -8px -8px;

        > .button, > .button-link {
            margin: 8px;
        }
    }

    @media screen and (max-width: 1080px) {
        > .address {
            flex-direction: column;

            > .data {
                align-items: center;
                margin-right: 0;
                margin-bottom: 10px;

                > .detail {
                    text-align: center;
                }
            }
        }

        .controls {
            flex-direction: column;
            margin-top: 32px;

            > * {
                &:not(:first-child) {
                    margin-top: 24px;
                }
            }
        }
    }
}
