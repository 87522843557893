@import "src/colors";

.dropdown-section {
    > .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 72px;
        padding: 0 24px 0 52px;
        border-radius: 20px;
        background-color: $secondary;
        color: #ffffff;
        font-family: Rota-Bold;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        cursor: pointer;

        .caret {
            margin-bottom: 4px;
        }
    }
}
