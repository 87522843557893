@import "src/colors";
@import "src/fonts";

.infinite-select {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $primary;

    .title {
        font-family: Rota-Regular;
        font-size: 14px;
        letter-spacing: 2.4px;
        line-height: 20px;
        letter-spacing: 1px;
        align-self: center;
    }

    .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        color: $primary;
        font-family: Rota-Bold;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 24px;
        cursor: pointer;

        .caret {
            margin-left: 6px;
            margin-bottom: 6px;
        }
    }

    .options {
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 100;
        background-color: #ffffff;
        max-height: 200px;
        overflow-y: auto;
        border-radius: 0 0 20px 20px;
        box-shadow: 10px 10px 10px rgb(0 0 0 / 10%);

        .option {
            padding: 16px;
            cursor: pointer;
        }
    }

    &.white {
        border-bottom: 1px solid $white;

        .title {
            color: $white;
        }

        .label {
            color: $white;
        }
    }

    &.secondary {
        border-bottom: 1px solid $secondary;

        .label {
            color: $dark;
            font-family: Rota-Regular;
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 20px;
        }
    }

    &.in-order {
        min-width: 150px;

        .label {
            flex: 1;
        }
    }
}
