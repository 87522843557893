@import "src/colors";

.womancare-academy-header {
    position: relative;
    padding-bottom: 150px;

    > .header-background {
        background: url("/assets/images/womancare-academy-header.png") center / cover;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        &::before {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            content: "";
            background-color: rgba($primary, 0.8);
        }
    }

    > .header-wrapper {
        position: relative;
        margin: 0 72px 32px 72px;
        padding-top: 34px;
        color: white;

        .header-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 27px;

            .title-wrapper {
                display: flex;
                flex-direction: column;

                .p {
                    margin-top: 24px;
                }
            }

            .logo {
                padding: 17px 28px;
                background-color: white;
                border-radius: 10px;
            }
        }

        .links {
            margin-top: 52px;
            display: flex;
            flex-direction: row;

            > :not(:first-child) {
                margin-left: 14px;
            }
        }
    }

    > .wave {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
    }

    @media screen and (max-width: 1080px) {
        > .header-wrapper {
            .header-content {
                flex-direction: column;
                align-items: flex-start;

                .logo {
                    margin-top: 24px;
                }
            }

            .links {
                flex-direction: column;
                .caret-link {
                    margin-bottom: 14px;
                    margin-left: 0;
                    text-align: center;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        > .header-wrapper {
            margin: 0 0;
            .header-content {
                align-items: center;
                .logo {
                    width: 100%;
                    padding: 17px 0;
                }
            }
        }
    }
}
