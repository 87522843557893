@import "src/colors";

.newsletter-subscribe-card {
    position: relative;
    background-color: white;
    border-radius: 20px;
    padding: 48px 55px;

    .newsletter-email-input-wrapper {
        max-width: 416px;
    }

    .button-subscribe-wrapper {
        margin-top: 24px;
    }
}
