@import "./colors.scss";
@import "./constants.scss";
@import "./fonts.scss";

@font-face {
    font-family: "Rota-Bold";
    src: local("Rota"), url(./assets/fonts/Rota-Bold.otf) format("truetype");
}

@font-face {
    font-family: "Rota-ExtraBold";
    src: local("Rota"), url(./assets/fonts/Rota-ExtraBold.otf) format("truetype");
}

@font-face {
    font-family: "Rota-BoldItalic";
    src: local("Rota"), url(./assets/fonts/Rota-BoldItalic.otf) format("truetype");
}

@font-face {
    font-family: "Rota-Italic";
    src: local("Rota"), url(./assets/fonts/Rota-Italic.otf) format("truetype");
}

@font-face {
    font-family: "Rota-Regular";
    src: local("Rota"), url(./assets/fonts/Rota-Regular.otf) format("truetype");
}

@font-face {
    font-family: "Rota-Medium";
    src: local("Rota"), url(./assets/fonts/Rota-Medium.otf) format("truetype");
}

@font-face {
    font-family: "Rota-MediumItalic";
    src: local("Rota"), url(./assets/fonts/Rota-MediumItalic.otf) format("truetype");
}

* {
    box-sizing: border-box;
}

body {
    font-family: "Rota-Regular", Arial, sans-serif;
    font-size: 16px;
    color: $dark;
}

#root {
    position: relative;
    padding-top: $header-height;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 {
    font-family: Rota-Bold;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 48px;
}

p {
    margin: 0;
}

.formatted-text {
    br {
        &:first-child,
        &:last-child {
            display: none;
        }
    }

    > div {
        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    ul {
        list-style: none;
        padding-left: 14px;
    }

    li {
        position: relative;
        text-indent: 0;

        &:not(:first-child) {
            margin-top: 16px;
        }

        &::before {
            position: absolute;
            content: "";
            top: 6px;
            left: -14px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $primary;
        }
    }
}

a {
    color: inherit;
    text-decoration: none;
}

a:not(.button-link),
.link-text {
    &.primary,
    &.secondary,
    &.white {
        width: auto;
        padding-bottom: 2px;
        font-family: "Rota-ExtraBold";
        font-size: 12px;
        font-weight: 800;
        letter-spacing: 1.5px;
        line-height: 14px;
        text-transform: uppercase;
    }

    &.primary {
        border-bottom: 1px solid $primary;
        color: $primary;
    }

    &.secondary {
        border-bottom: 1px solid $secondary;
        color: $secondary;
    }

    &.white {
        border-bottom: 1px solid $white;
        color: $white;
    }
}

button {
    background: transparent;
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &.link-text {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

input[type="text"],
input[type="number"],
input[type="password"] {
    background-color: transparent;
    border: none;
    font-family: Rota-Regular, Arial, sans-serif !important;

    &:focus {
        outline: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        background-color: transparent !important;
        box-shadow: 0 0 0 30px #ffffff inset !important;
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
        font-family: Rota-Regular, Arial, sans-serif !important;
    }

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
        font-family: Rota-Regular, Arial, sans-serif !important;
    }
}

textarea {
    &:focus {
        outline: none;
    }
}

.row {
    display: flex;
    flex-direction: row;
}

.col,
.column {
    display: flex;
    flex-direction: column;
}

.modal.error {
    padding: 20px 30px;
    font-family: $bold;
    color: $error;
}

.app.success {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 30px;
    background-color: $success;
    color: $white;
    font-family: $bold;
    cursor: pointer;
    z-index: 5000;
}

#loading {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $white;
}

@media screen and (max-width: 1220px) {
    #root {
        padding-top: 0;
    }
}
