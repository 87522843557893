.calendar-container {
    background-color: white;
    display: inline-flex;
    flex-direction: column;
    border-radius: 12px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    .wave-container {
        position: relative;
        width: 500px;
        height: 110px;

        .wave {
            border-radius: 12px 12px 0px 0px;
        }

        .stack {
            position: absolute;
            top: 0;
            left: 0;
        }

        .stack-top {
            width: 100%;
            height: 70%;
            z-index: 9;
        }

        .row {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex: 1;
            padding-left: 126px;
            padding-right: 126px;
        }

        .calendar-title {
            color: white;
            font-size: 16;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}
