@import "src/colors";
@import "src/fonts";

#company {
    padding-bottom: 80px;

    h1 {
        margin-top: 36px;
    }

    > .addresses {
        display: flex;
        margin-top: 72px;

        > .col {
            flex: 1;

            &:not(:first-child) {
                margin-left: 24px;
            }

            h2 {
                font-family: Rota-Bold;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 38px;
            }

            .block {
                flex: 1;
                position: relative;
                margin-top: 48px;
                padding: 64px 55px 44px;
                border: 2px solid $dark;
                border-radius: 20px;

                > .icon-link {
                    position: absolute;
                    top: 16px;
                    right: 32px;
                }

                h3 {
                    margin-bottom: 32px;
                    font-family: Rota-BoldItalic;
                    font-size: 26px;
                    font-style: italic;
                    font-weight: bold;
                    letter-spacing: 1px;
                    line-height: 28px;
                }

                .address {
                    > .title {
                        position: relative;
                        margin-left: 28px;
                        font-family: "Rota-MediumItalic";
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        line-height: 25px;
                        text-transform: uppercase;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 10px;
                            left: -28px;
                            display: flex;
                            margin-right: 8px;
                            height: 1px;
                            width: 20px;
                            background-color: $primary;
                        }
                    }

                    > .content {
                        margin-top: 24px;
                        font-size: 14px;
                        letter-spacing: 1px;
                        line-height: 22px;

                        > .name {
                            color: $primary;
                            font-family: Rota-Bold;
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 1px;
                            line-height: 22px;
                        }

                        > .bottom {
                            margin-top: 16px;
                        }
                    }
                }

                > .secondary {
                    margin-top: 32px;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        > .back-link,
        > .addresses {
            padding-left: 32px;
            padding-right: 32px;
        }

        > .addresses {
            flex-direction: column;

            > .col {
                &:not(:first-child) {
                    margin-left: 0;
                    margin-top: 60px;
                }

                .block {
                    padding: 48px 16px;

                    > .icon-link {
                        top: 8px;
                        right: 16px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        > .addresses {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}
