@import "src/colors";
@import "src/fonts";

#signup.documents {
    .wrapper {
        padding: 0 32px 80px;

        .form {
            margin-top: 52px;

            section {
                .title {
                    display: flex;

                    .check-input {
                        align-self: center;
                        margin-left: 60px;
                    }
                }

                .inputs {
                    margin: 16px -35px -20px;

                    .file-input,
                    .check-input {
                        width: calc(50% - 70px);
                        margin: 20px 35px;
                    }

                    .check-input {
                        align-self: center;
                    }
                }

                &.due-diligence {
                    .inputs {
                        & > :nth-child(9) {
                            align-self: flex-start;
                        }
                    }
                }

                &.business-license {
                    .inputs {
                        & > :nth-child(1) {
                            align-self: flex-start;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        .wrapper {
            .form {
                section {
                    .inputs {
                        margin: 24px -12px -12px;

                        > .file-input,
                        > .check-input {
                            width: 100%;
                            margin: 12px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .wrapper {
            .back-link {
                margin-left: 32px;
            }

            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
    }

    @media screen and (max-width: 480px) {
        .wrapper {
            .form {
                section {
                    .title {
                        flex-direction: column;

                        .check-input {
                            align-self: flex-start;
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
