@import "src/colors";
@import "src/fonts";

#orders {
    display: flex;
    flex-direction: column;
    padding: 48px 72px 80px;
    background-color: $light;

    h1 {
        font-family: Rota-Bold;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 38px;
    }

    > .filters {
        margin-top: 28px;

        > .inputs {
            margin-top: 24px;
            width: 250px;

            > :not(:first-child) {
                margin-top: 20px;
            }

            &.hidden {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 1px;
                margin: -1px;
                border: 0;
                clip: rect(0 0 0 0);
            }
        }
    }

    table {
        margin-top: 56px;
        border-collapse: collapse;

        th,
        td {
            padding: 24px 10px 24px 0;
            text-align: left;
        }

        th {
            color: $gray;
            font-family: Rota-Bold;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 18px;
        }

        tbody {
            tr {
                td {
                    border-top: 1px solid #d1d1d1;

                    &:nth-child(2) {
                        font-family: Rota-ExtraBold;
                        font-size: 16px;
                        font-weight: 800;
                        letter-spacing: 1px;
                        line-height: 20px;
                    }

                    &:nth-child(4) {
                        font-family: Rota-Bold;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        line-height: 20px;
                    }

                    &:nth-child(9) {
                        #actionsCell {
                            align-items: stretch;
                            display: flex;
                            > :not(:first-child) {
                                margin-left: 16px;
                            }
                        }
                    }

                    &.error {
                        color: $error;
                    }

                    &.success-outline {
                        color: $success-outline;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        padding-left: 32px;
        padding-right: 32px;
        table {
            tbody {
                tr {
                    td {
                        &:nth-child(9) {
                            #actionsCell {
                                flex-direction: column;

                                > :not(:first-child) {
                                    margin-left: 0;
                                    margin-top: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        table {
            thead {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 1px;
                margin: -1px;
                border: 0;
                clip: rect(0 0 0 0);
            }

            tbody {
                tr {
                    display: flex;
                    flex-direction: column;
                    padding: 12px 0;

                    &:not(:first-child) {
                        border-top: 1px solid #d1d1d1;
                    }

                    td {
                        border-top: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px 0;
                        text-align: right;

                        &::before {
                            content: attr(data-label);
                            font-family: Rota-ExtraBold;
                            font-size: 12px;
                            font-weight: 800;
                            letter-spacing: 1.5px;
                            line-height: 14px;
                            text-align: left;
                            text-transform: uppercase;
                        }

                        &:nth-child(5) {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}
