@import "src/colors";

.calendar-cell {
    position: relative;
    display: inline-flex;
}

.calendar-text {
    z-index: 9;
    font-size: 1.25rem;
}

.calendar-badge {
    position: absolute;
    top: -3px;
    right: -7px;
    padding: 7px;
    border-radius: 50%;
    background: $secondary;
}

.calendar-tooltip {
    display: inline-block;
    position: relative;
    text-align: left;

    ul {
        list-style-type: none;
        padding: 0;
    }

    h3 {
        margin: 0 0 12px;
    }

    .calendar-text-content {
        padding: 10px 20px;
    }

    .calendar-right {
        min-width: 200px;
        max-width: 400px;
        top: 50%;
        left: 100%;
        margin-left: 20px;
        transform: translate(0, -50%);
        padding: 0;
        color: $light;
        background-color: $darkgray;
        font-weight: normal;
        font-size: 13px;
        border-radius: 8px;
        position: absolute;
        z-index: 99999999;
        box-sizing: border-box;
        box-shadow: 0 1px 8px $gray;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.8s;

        .calendar-tooltip-body {
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -12px;
            width: 12px;
            height: 24px;
            overflow: hidden;
        }

        .calendar-tooltip-body::after {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            left: 0;
            top: 50%;
            transform: translate(50%, -50%) rotate(-45deg);
            background-color: $darkgray;
            box-shadow: 0 1px 8px $gray;
        }
    }

    &:hover .calendar-right {
        visibility: visible;
        opacity: 1;
    }
}
