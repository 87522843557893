@import "src/colors";

#order-shipping {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 80px;
    background-color: $light;

    h1 {
        margin-top: 32px;
    }

    .subtitle {
        margin-top: 24px;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 22px;
    }

    > .content {
        display: flex;
        align-items: flex-start;
        margin-top: 80px;

        > .selection {
            flex: 2;

            .method {
                position: relative;
                padding: 20px 20px 20px 32px;
                border-radius: 20px;
                background-color: #ffffff;
                cursor: pointer;

                &:not(:first-child) {
                    margin-top: 16px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 22px;
                    right: 22px;
                    width: 10px;
                    height: 10px;
                    border: 1px solid $primary;
                    border-radius: 50%;
                }

                > .title {
                    font-family: Rota-Bold;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 1.13px;
                    line-height: 22px;
                }

                .radio-input {
                    margin-top: 40px;
                }

                .text-input {
                    margin-top: 20px;
                }

                &.active {
                    background-color: $dark;

                    &::after {
                        background-color: $primary;
                    }

                    > .title {
                        color: #ffffff;
                    }
                }
            }
        }

        > .validation {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-left: 78px;
            padding: 44px 55px 48px;
            border: 2px solid $dark;
            border-radius: 20px;

            > .title {
                display: inline-flex;
                padding-bottom: 24px;
                border-bottom: 2px solid $dark;
                font-family: Rota-Bold;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 20px;

                .tooltip {
                    display: inline-flex;
                    margin-left: 5px;
                }
            }

            > .info {
                margin-top: 24px;
                letter-spacing: 1px;
                line-height: 22px;
            }

            > .button {
                margin-top: 36px;
            }
        }
    }

    .shipping-info {
        position: relative;
        display: flex;
        justify-content: center;
        border-radius: 20px;

        > .image {
            width: 80%;
            height: 100%;

            > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .close {
            position: absolute;
            top: 16px;
            right: 26px;
            width: 40px;
            height: 40px;
        }
    }

    @media screen and (max-width: 1080px) {
        > .content {
            flex-direction: column;
            align-items: stretch;

            > .validation {
                margin-left: 0;
                margin-top: 78px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .shipping-info {
            border-radius: 0;
        }
    }
}
