@import "src/colors";

.dropdown-checkbox {
    position: relative;
    display: flex;

    .input {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .title {
            margin-right: 20px;
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 20px;
        }

        .label {
            display: flex;
            align-items: center;
            font-family: Rota-ExtraBold;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 2px;
            line-height: 18px;
            text-transform: uppercase;

            .caret {
                margin-left: 6px;
                margin-bottom: 6px;
            }
        }
    }

    .items {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.7);
        max-height: 200px;
        overflow-y: auto;

        .item {
            padding: 16px;
            color: $white;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &:not(:first-child) {
                border-top: 1px solid $gray;
            }
        }
    }
}
