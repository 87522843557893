@import "src/colors";
@import "src/fonts";

#notifications {
    display: flex;
    flex-direction: column;
    padding: 32px 0 80px;
    background-color: $light;

    h1,
    .back-link {
        padding: 0 72px;
    }

    h1 {
        margin-top: 36px;
    }

    table {
        margin-top: 24px;
        border-collapse: collapse;

        th,
        td {
            &:nth-child(1) {
                padding-left: 72px;
            }

            &:nth-child(4) {
                padding-right: 72px;
            }
        }

        th {
            padding: 16px 10px 16px 0;
            font-family: $bold;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            text-align: left;
            color: $gray;
        }

        td {
            padding: 28px 10px 28px 0;
        }

        tbody {
            tr {
                background-color: #ffffff;

                td {
                    border-top: 1px solid #d1d1d1;
                    color: $gray;

                    &:nth-child(2) {
                        font-family: $extra-bold;
                        font-size: 16px;
                        font-weight: 800;
                        letter-spacing: 1px;
                        line-height: 20px;
                    }

                    &:nth-child(4) {
                        > .actions {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            > :not(:first-child) {
                                margin-left: 16px;
                            }
                        }
                    }
                }

                &.unread {
                    background-color: $primary;
                    cursor: pointer;

                    td {
                        border-top: 1px solid #ffffff;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        h1,
        .back-link {
            padding: 0 32px;
        }

        table {
            th,
            td {
                &:nth-child(1) {
                    padding-left: 0;
                }

                &:nth-child(4) {
                    padding-right: 0;
                }
            }

            thead {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 1px;
                margin: -1px;
                border: 0;
                clip: rect(0 0 0 0);
            }

            tbody {
                tr {
                    display: flex;
                    flex-direction: column;
                    padding: 12px 16px;
                    border-top: 1px solid #d1d1d1;

                    td {
                        border-top: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px 0;
                        text-align: right;

                        &::before {
                            content: attr(data-label);
                            margin-right: 8px;
                            font-family: $extra-bold;
                            font-size: 12px;
                            font-weight: 800;
                            letter-spacing: 1.5px;
                            line-height: 14px;
                            text-align: left;
                        }

                        &.no-action {
                            display: none;
                        }
                    }

                    &.unread {
                        td {
                            border-top: none;
                        }
                    }
                }
            }
        }
    }
}
