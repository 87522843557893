@import "src/colors";

.file-input {
    .label {
        flex: 1 0;
        margin-right: 10px;
        color: #ffffff;
        font-family: Rota-ExtraBold;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 20px;
    }

    .file-label {
        margin-right: 12px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #ffffff;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 20px;
    }

    .right {
        display: flex;
        align-items: center;
    }

    .files {
        margin-bottom: 21px;

        .file-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 21px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    label {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .right {
            .button {
                flex: 0 0 auto;
            }

            .files {
                display: flex;
                margin-left: 10px;

                .download-link {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 1px;
            margin: -1px;
            border: 0;
            clip: rect(0 0 0 0);
        }
    }

    &.dark {
        .label {
            color: $dark;
        }

        .file-label {
            color: $dark;
        }
    }

    @media screen and (max-width: 480px) {
        .file-label {
            max-width: 100px;
        }

        label {
            flex-direction: column;
            align-items: flex-start;

            .right {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
}
