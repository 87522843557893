@import "src/colors";
@import "src/fonts";

#forecast-form {
    padding-bottom: 80px;

    h1 {
        margin-top: 36px;
    }

    .form {
        section {
            &:not(:first-child) {
                margin-top: 64px;
            }

            > .title {
                margin-bottom: 40px;
            }

            .inputs {
                &:first-child {
                    margin-top: 0;
                }
            }

            .months {
                .month-row {
                    &:not(:first-child) {
                        margin-top: 36px;
                    }

                    > .title {
                        color: $secondary;
                        font-family: Rota-Bold;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        line-height: 24px;
                    }

                    .inputs {
                        margin-top: 12px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        > .back-link {
            padding-left: 32px;
        }
    }
}
