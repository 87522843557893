@import "src/colors";

.shop-filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .items {
        left: -28px;
        width: calc(100% + 56px);
    }

    .inputs {
        // position: absolute;
        background: white;
        border-radius: 20px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin-top: 10px;
        width: 350px;

        .dropdown-input {
            margin: 8px;
        }
    }

    .criteria {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 32px;

        .criterion {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            border: 1px solid $secondary;

            &:not(:first-child) {
                margin-top: 16px;
            }

            .label {
                padding-top: 4px;
                color: $secondary;
                font-family: Rota-Medium;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 15px;
                text-transform: uppercase;
            }

            .close {
                width: 24px;
                height: 24px;
                margin-left: 8px;
            }
        }
    }

    .see-all {
        margin-top: 32px;
    }
}
