@import "src/colors.scss";

#resourceCategory {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: white;
    border-radius: 20px;
    padding: 55px 40px;

    .logo-wrapper {
        width: 86px;
        height: 86px;
        padding: 23px 23px;
        border-radius: 50%;
        background-color: $dark;
        flex-shrink: 0;

        .logo {
            width: 100%;
            height: 100%;
        }
    }

    .text-content {
        margin-left: 24px;

        .category-name {
            margin-top: 8px;
            width: 100%;
            font-family: Rota-MediumItalic;
            font-size: 26px;
            font-style: italic;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 30px;
        }

        .category-excerpt {
            margin-top: 24px;
            margin-bottom: 24px;
            font-family: Rota-Regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 22px;
        }
    }

    @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: center;
        .text-content {
            margin-left: 0;
            margin-top: 24px;
        }
    }
}
