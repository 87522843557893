.check-input {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    input {
        margin: 0;
        cursor: pointer;
    }

    .label {
        margin-left: 10px;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 18px;
        font-family: Rota-Regular;
        font-style: normal;
        font-weight: normal;
    }

    &.dark {
        .label {
            color: #ffffff;
        }
    }
}
