@import "src/colors";
@import "src/fonts";

#signup {
    display: flex;
    flex-direction: column;
    background-color: $light;

    .header {
        position: relative;
        height: 351px;

        .content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1;

            h1 {
                margin-top: 20px;
                color: #ffffff;
            }
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("/assets/images/home-header.png") center / cover;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($secondary, 0.6);
            }
        }

        .wave {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 120%;
            height: 80px;
        }
    }

    .wrapper {
        padding: 52px 128px 80px;

        .form {
            display: flex;
            flex-direction: column;
            padding: 52px 55px 48px;
            border-radius: 20px;
            background-color: $dark;

            section {
                &:not(:first-child) {
                    margin-top: 80px;
                }

                > .title {
                    margin-bottom: 40px;

                    h2 {
                        color: #ffffff;
                        font-family: Rota-BoldItalic;
                        font-size: 26px;
                        font-style: italic;
                        font-weight: bold;
                        letter-spacing: 1px;
                        line-height: 28px;
                    }
                }

                .inputs {
                    margin: 24px -12px -12px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;

                    > div {
                        width: calc(50% - 24px);
                        margin: 12px;
                    }

                    .select {
                        align-self: stretch;
                    }
                }
            }

            .submit-block {
                margin-top: 48px;
                align-self: flex-start;

                .error {
                    margin-top: 16px;
                    color: red;
                }
            }

            .note {
                margin-top: 32px;
                color: #ffffff;
                font-size: 12px;
                letter-spacing: 1px;
                line-height: 16px;
            }
        }
    }

    @media screen and (max-width: 1080px) {
        .wrapper {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

    @media screen and (max-width: 768px) {
        .header {
            .content {
                h1 {
                    font-size: 32px;
                    line-height: 32px;
                }
            }
        }

        .wrapper {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            .form {
                padding: 52px 32px 48px;
                border-radius: 0;

                section {
                    .inputs {
                        > div {
                            width: 100%;
                            margin: 12px;
                        }
                    }
                }
            }
        }
    }
}
