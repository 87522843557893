#not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 120px 0;

    > .button-link {
        margin-top: 20px;
    }
}
