@import "src/colors";
@import "src/fonts";

#my-loyalty-program {
    padding: 32px 72px;

    .loyalty-program-logo-wrapper {
        margin: 30px 0;
        display: flex;
        justify-content: center;
    }

    .partnercare-loyalty-program-logo {
        width: 560px;
        max-width: 100%;
    }

    .section-top {
        display: grid;
        grid-template-areas:
            "presentation pyramid"
            "presentation carecoins";
        grid-template-columns: 3fr minmax(350px, 2fr);
        grid-template-rows: 4fr 1fr;
        margin-bottom: 70px;

        .presentation {
            grid-area: presentation;
            padding-right: 40px;

            h1 {
                color: $dark;
                font-family: $regular;
                font-size: 20px;
            }

            p {
                color: $dark;
                font-family: $regular;
                font-size: 16px;
                margin-bottom: 10px;
            }

            ul {
                color: $dark;
                font-family: $regular;
                font-size: 16px;
                margin: 0;

                li {
                    margin-bottom: 5px;
                }
            }

            > ul {
                padding-left: 1em;

                > li > ul {
                    padding-left: 2em;
                }
            }
        }

        .pyramid {
            grid-area: pyramid;

            .pyramid-explanation {
                color: #57585a;
                font-family: $bold;
                font-size: 16px;
                margin-bottom: 5px;
            }

            img {
                width: 100%;
            }
        }

        .my-care-coins {
            grid-area: carecoins;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            color: $dark;
            font-family: $regular;
            font-size: 20px;
            text-align: right;

            .my-care-coins-amount {
                font-size: 24px;
            }
        }
    }

    .section-title {
        color: $dark;
        font-family: $regular;
        font-size: 36px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 22px;

        &.loyalty-statuses {
            margin-bottom: 172px;
        }

        &.partners-shop {
            margin-top: 70px;
            margin-bottom: 40px;
        }
    }

    .loyalty-status-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .statuses-informations {
        color: $dark;
        font-family: $regular;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 20px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .shop-carecoins-balance {
        color: $dark;
        font-family: $regular;
        font-size: 18px;
        margin: 10px 0;
    }

    .partners-shop-table {
        width: 100%;
        text-align: left;

        thead {
            background-color: $primary;
            color: white;

            th {
                padding: 15px 20px;

                &:not(:last-child) {
                    border-right: 1px solid rgba(0, 0, 0, 0.25);
                }
            }
        }

        tbody {
            tr:nth-child(2n + 1) {
                background-color: transparentize($color: $primary, $amount: 0.95);
            }

            tr:nth-child(2n) {
                background-color: transparentize($color: $primary, $amount: 0.8);
            }

            tr > td {
                padding: 10px 20px;

                &:not(:last-child) {
                    border-right: 1px solid rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    .partners-shop-table {
        .table-tr-sm button {
            margin: auto;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 32px 32px;

        .section-top {
            grid-template-areas:
                "presentation"
                "pyramid"
                "carecoins";
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            margin-bottom: 70px;

            .presentation {
                margin-bottom: 30px;
            }

            .my-care-coins {
                padding-top: 10px;
            }
        }

        .section-title {
            text-align: center;
            margin-bottom: 120px;
        }

        .table-th-lg,
        .table-td-lg {
            display: none;
        }

        tbody {
            tr > td {
                padding: 10px 20px !important;
                border-right: 1px solid rgba(0, 0, 0, 0.25);
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);

                &:first-child {
                    border-left: 1px solid rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    @media screen and (min-width: 769px) {
        .table-tr-sm {
            display: none;
        }
    }

    @media screen and (max-width: 480px) {
        padding: 32px 16px;

        .loyalty-program-logo-wrapper {
            margin-top: 30px;
            margin-bottom: 50px;
        }

        .my-care-coins-wrapper {
            margin: 0 0 40px;
        }

        .section-title {
            font-size: 28px;
        }
    }
}
