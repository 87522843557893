.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    cursor: default;

    .modal {
        position: relative;
        width: 67%;
        max-height: 100%;
        overflow: auto;
        background-color: #ffffff;
        border-radius: 20px;

        > .close {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
        }
    }

    @media screen and (max-width: 768px) {
        .modal {
            width: 100%;
            border-radius: 0;
        }
    }
}
