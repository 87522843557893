@import "src/colors";
@import "src/fonts";

#user-form {
    h1 {
        margin-top: 36px;
    }

    > .form {
        section {
            .inputs {
                margin: -12px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        > .back-link {
            padding-left: 32px;
        }
    }
}
