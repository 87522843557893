@import "src/colors.scss";
@import "src/fonts.scss";

#home {
    display: flex;
    flex-direction: column;

    .dash-title {
        position: relative;
        margin-left: 28px;
        font-family: "Rota-MediumItalic";
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 25px;
        text-transform: uppercase;

        &::before {
            content: "";
            position: absolute;
            top: 10px;
            left: -28px;
            display: flex;
            margin-right: 8px;
            height: 1px;
            width: 20px;
            background-color: $primary;
        }
    }

    .header {
        position: relative;

        .background {
            position: relative;
            height: 300px;
            background: url("/assets/images/home-header.png") center / cover;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.8);
            }
        }

        .wave {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 120%;
            height: 80px;
        }

        .title-block {
            position: absolute;
            top: 72px;
            left: 72px;
            font-size: 36px;

            .title {
                font-family: Rota-Bold;

                .name {
                    color: $primary;
                }
            }

            .subtitle {
                margin-top: -5px;
                font-family: Rota-Italic;
            }
        }

        .program {
            position: absolute;
            bottom: -32px;
            right: 72px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 26px 26px;
            background-color: $dark;
            border-radius: 20px;
            z-index: 3;

            .level {
                margin-top: 4px;
                font-family: Rota-ExtraBold;
                font-size: 12px;
                font-weight: 800;
                letter-spacing: 2.4px;
                line-height: 14px;
                text-transform: uppercase;
            }

            a {
                padding-bottom: 4px;
                border-bottom: 1px solid #ffffff;
                font-family: Rota-ExtraBold;
                font-size: 12px;
                letter-spacing: 1.5px;
                font-weight: 800;
                color: #ffffff;
                text-transform: uppercase;
            }
        }
    }

    .partner-badge {
        width: 40px;
    }

    .partner-level-name {
        font-family: $bold;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1.5px;
        margin: 10px 0;
        color: $primary;
        text-align: center;
    }
}
