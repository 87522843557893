@import "src/colors";

#order-address {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 80px;

    h1 {
        margin-top: 32px;
    }

    .subtitle {
        margin-top: 24px;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 22px;
    }

    .addresses {
        display: flex;
        flex-wrap: wrap;
        margin: 75px -12px -12px;

        .address-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 12px;
            width: calc(100% / 3 - 24px);
            padding: 55px;
            border: 1px solid #e5e5e5;
            border-radius: 20px;
            background-color: $light;

            > .title {
                position: relative;
                margin-left: 28px;
                font-family: "Rota-MediumItalic";
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 25px;
                text-transform: uppercase;

                &::before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: -28px;
                    display: flex;
                    margin-right: 8px;
                    height: 1px;
                    width: 20px;
                    background-color: $primary;
                }
            }

            > .content {
                > .name {
                    margin-top: 34px;
                    margin-bottom: 16px;
                    color: $primary;
                    font-family: Rota-Bold;
                    font-weight: bold;
                    letter-spacing: 1px;
                    line-height: 24px;
                }

                > .detail {
                    margin-top: 24px;
                    margin-bottom: 16px;
                    letter-spacing: 1px;
                    line-height: 22px;
                }

                > .email,
                .phone {
                    letter-spacing: 1px;
                    line-height: 22px;
                }
            }

            > .icon-link {
                margin-top: 32px;
            }

            > .contact-form {
                margin-top: 32px;

                > :not(:first-child) {
                    margin-top: 32px;
                }

                > .buttons {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 32px -8px -8px;

                    > .button {
                        margin: 8px;
                    }
                }
            }
        }
    }

    > .button {
        align-self: center;
        margin-top: 56px;
    }

    @media screen and (max-width: 1080px) {
        .addresses {
            .address-block {
                width: calc(100% / 2 - 24px);
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 32px 32px 80px;

        .addresses {
            .address-block {
                width: 100%;
                padding: 32px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        padding: 32px 16px 80px;

        .addresses {
            .address-block {
                width: 100%;
                padding: 32px;
            }
        }
    }
}
