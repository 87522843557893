@import "src/colors";
@import "src/fonts";

.date-input {
    position: relative;
    border-bottom: 1px solid $secondary;

    .label-wrapper {
        display: flex;
        align-items: flex-end;

        .label {
            font-family: $bold;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2.4px;
            line-height: 16px;
            text-transform: uppercase;
        }
    }

    .react-date-picker__wrapper {
        height: 35px;
        border: none;

        > button {
            display: none;
        }
    }

    .react-date-picker__calendar {
        z-index: 100;
    }

    .error-text {
        margin-top: 4px;
        color: red;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
    }

    .success-text {
        color: green;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        position: absolute;
        bottom: -17px;
        left: 20px;
    }

    &.dark {
        border-bottom: 1px solid #ffffff;

        .label,
        .react-date-picker__inputGroup > * {
            color: #ffffff;
        }
    }

    &.primary {
        border-bottom: 1px solid $primary;
    }
}
