@import "src/colors";

#order-certificates {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 80px;

    h1 {
        margin-top: 32px;
    }

    .subtitle {
        margin-top: 24px;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 22px;
    }

    .content {
        display: flex;
        margin: 50px auto 12px;

        .title {
            display: flex;
            align-items: center;
            padding: 0 24px 32px;
            border-bottom: 1px solid #e5e5e5;
        }

        .certificates {
            > .title {
                margin-top: 16px;
                margin-bottom: 27px;
                font-family: Rota-Bold;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 24px;
            }
        }
    }

    > .button {
        align-self: center;
        margin-top: 56px;
    }
}
