@import "src/colors";
@import "src/fonts";

.buttons-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

#orderDetails {
    display: flex;
    flex-direction: row;
    background-color: $light;

    #order {
        flex: 1;
        display: flex;
        flex-direction: column;

        > .section {
            display: flex;
            flex-direction: column;

            h1 {
                margin-top: 25px;
                font-family: Rota-Bold;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 38px;
                display: flex;

                :first-child {
                    margin-left: 16px;
                }
            }

            #details {
                font-family: $medium;
                font-size: 16px;
                font-weight: 500;
                align-items: left;
                letter-spacing: 1px;
                line-height: 20px;
                margin-top: 30px;

                > :not(:first-child) {
                    margin-top: 16px;
                }
            }

            &#status {
                margin-top: 66px;
            }

            &#rejection-modal {
                padding: 50px;
            }

            &#products {
                margin-top: 66px;
            }

            &#bottomDetail {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 44px;
            }

            > .title {
                position: relative;
                margin-left: 28px;
                font-family: "Rota-MediumItalic";
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 25px;
                text-transform: uppercase;
                &::before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: -28px;
                    display: flex;
                    margin-right: 8px;
                    height: 1px;
                    width: 20px;
                    background-color: $primary;
                }
            }

            #statusTable {
                margin-top: 20px;
                width: fit-content;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 24px;

                .round-rating {
                    margin: 16px 0;
                }
            }

            table {
                border-collapse: collapse;

                .rejection-modal {
                    max-width: 600;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: 20px;
                    padding: 50px;
                }

                &#productsTable {
                    margin-top: 20px;

                    th {
                        padding-bottom: 20px;
                        border-bottom: 1px solid #d1d1d1;
                        font-family: Rota-ExtraBold;
                        font-size: 12px;
                        font-weight: 800;
                        letter-spacing: 1.5px;
                        line-height: 14px;
                        text-transform: uppercase;
                        text-align: left;
                    }

                    .card {
                        display: flex;
                        flex-direction: column;
                    }

                    tr {
                        &.product-block {
                            td {
                                align-items: center;
                                text-align: left;
                                padding-top: 18px;
                                padding-bottom: 24px;
                                padding-right: 30px;
                                letter-spacing: 1px;
                                line-height: 22px;

                                &:nth-child(1) {
                                    width: 15%;
                                }

                                &:nth-child(2) {
                                    width: 25%;
                                }

                                &:nth-child(3) {
                                    width: 20%;
                                }

                                &:nth-child(4) {
                                    width: 20%;
                                }

                                &:nth-child(5) {
                                    width: 20%;
                                }

                                > .image-wrapper {
                                    position: relative;

                                    > .image {
                                        width: 86px;
                                        height: 86px;
                                        padding: 10px;
                                        border-radius: 50%;
                                        object-fit: contain;
                                        background-color: #ffffff;
                                    }
                                }
                                > .name {
                                    font-family: Rota-Italic;
                                    font-style: italic;
                                    letter-spacing: 1px;
                                    line-height: 24px;
                                    text-align: left;
                                    font-size: 16px;
                                }

                                > .reference {
                                    margin-top: 8px;
                                    color: $primary;
                                    font-family: Rota-ExtraBold;
                                    font-size: 12px;
                                    font-weight: 800;
                                    letter-spacing: 1.5px;
                                    line-height: 14px;
                                    text-transform: uppercase;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }

                &#totalTable {
                    align-self: flex-end;
                    margin-left: 16px;

                    tr {
                        &.sub-total {
                            font-family: Rota-Regular;
                            font-size: 12px;
                            letter-spacing: 1px;
                            line-height: 18px;

                            :nth-child(2) {
                                font-family: Rota-Bold;
                                font-weight: bold;
                                padding-left: 90px;
                            }

                            &.carecoins {
                                td {
                                    padding-top: 24px;
                                }
                            }
                        }

                        &#total {
                            td {
                                padding-top: 50px;
                                text-transform: uppercase;

                                &:nth-child(1) {
                                    font-family: Rota-Bold;
                                    font-size: 12px;
                                    font-weight: 800;
                                    letter-spacing: 1.5px;
                                    line-height: 14px;
                                }

                                &:nth-child(2) {
                                    color: $primary;
                                    font-family: Rota-ExtraBold;
                                    font-size: 24px;
                                    font-weight: 800;
                                    letter-spacing: 3px;
                                    line-height: 14px;
                                    padding-left: 74px;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }

        #buttons {
            margin-top: 46px;
            display: flex;
            justify-content: flex-end;
            > :not(:first-child) {
                margin-left: 16px;
            }
        }
    }

    .download-rectangle {
        align-self: flex-start;
        width: 360px;
        margin-left: 24px;
        border-radius: 20px;
        background-color: $white;

        #downloadContent {
            margin: 48px;
            display: flex;
            flex-direction: column;

            #downloadTitle {
                font-family: Rota-BoldItalic;
                font-size: 26px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 28px;
                margin-bottom: 30px;
            }

            table {
                border-collapse: collapse;

                .download-rows {
                    margin-top: 8px;
                    border-bottom: 1px solid $dark;
                    padding-bottom: 20px;
                    td {
                        &:nth-child(1) {
                            text-align: left;
                            font-family: Rota-Regular;
                            font-size: 14px;
                            letter-spacing: 1px;
                            line-height: 20px;
                        }
                        &:nth-child(2) {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        flex-direction: column;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 80px;

        h1 {
            padding: 0;
        }

        #order {
            > .section {
                &#bottomDetail {
                    flex-direction: column;
                }

                #buttons {
                    flex-direction: column;
                    align-content: center;

                    .button {
                        margin-left: 0;
                        width: 100%;
                        margin-top: 16px;
                    }
                }

                table#productsTable {
                    thead {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1px;
                        height: 1px;
                        margin: -1px;
                        border: 0;
                        clip: rect(0 0 0 0);
                    }

                    tbody {
                        tr {
                            &.product-block {
                                display: flex;
                                flex-direction: column;

                                td {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    border-bottom: none;
                                    text-align: right;

                                    &:nth-child(1),
                                    &:nth-child(2),
                                    &:nth-child(3),
                                    &:nth-child(4),
                                    &:nth-child(5) {
                                        width: 100%;
                                        padding-right: 0;
                                    }

                                    .reference {
                                        margin-left: 16px;
                                        text-align: right;
                                    }

                                    &::before {
                                        content: attr(data-label);
                                        font-family: Rota-ExtraBold;
                                        font-size: 12px;
                                        font-weight: 800;
                                        letter-spacing: 1.5px;
                                        line-height: 14px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                }

                table#totalTable {
                    margin-left: 0;
                    margin-top: 50px;

                    tbody {
                        tr {
                            &.sub-total {
                                :nth-child(2) {
                                    padding-left: 20px;
                                }
                            }

                            &#total {
                                td {
                                    &:nth-child(2) {
                                        padding-left: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .download-rectangle {
            width: 100%;
            margin-left: 0;
            margin-top: 36px;
        }
    }
}
