.request-modal {
    padding: 32px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .button {
        margin-top: 24px;
    }
}
