@import "src/colors";
@import "src/fonts";

#security {
    h1 {
        margin-top: 36px;
    }

    > .form {
        section {
            .inputs {
                margin: 24px -12px -16px;

                > div {
                    margin: 16px 12px;
                }
            }
        }
    }
}
