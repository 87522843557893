@import "src/colors";
@import "src/fonts";

#editOrder {
    display: flex;
    flex-direction: row;
    background-color: $light;

    #order {
        flex: 1;
        display: flex;
        flex-direction: column;

        > .section {
            display: flex;
            flex-direction: column;

            .link-button,
            .link-text {
                margin-left: 16px;
            }

            h1 {
                margin-top: 25px;
                font-family: Rota-Bold;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 38px;
                display: flex;

                :first-child {
                    margin-left: 16px;
                }
            }

            &#products {
                margin-top: 66px;
            }

            &#addAndTotal {
                margin-top: 24px;
                flex-direction: row;
                justify-content: space-between;

                > #totalTable {
                    tr {
                        &.sub-total {
                            font-family: Rota-Regular;
                            font-size: 12px;
                            letter-spacing: 1px;
                            line-height: 18px;

                            :nth-child(2) {
                                font-family: Rota-Bold;
                                font-weight: bold;
                                padding-left: 90px;
                            }

                            &.carecoins {
                                td {
                                    padding-top: 24px;
                                }
                            }
                        }

                        &#total {
                            td {
                                padding-top: 50px;
                                text-transform: uppercase;

                                &:nth-child(1) {
                                    font-family: Rota-Bold;
                                    font-size: 12px;
                                    font-weight: 800;
                                    letter-spacing: 1.5px;
                                    line-height: 14px;
                                }

                                &:nth-child(2) {
                                    color: $primary;
                                    font-family: Rota-ExtraBold;
                                    font-size: 24px;
                                    font-weight: 800;
                                    letter-spacing: 3px;
                                    line-height: 14px;
                                    padding-left: 74px;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }

            &#requestAndButtons {
                margin-top: 80px;
                flex-direction: row;
                justify-content: space-between;

                > #specialRequest {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    > .link-text {
                        margin-top: 20px;
                        border-bottom: none;
                    }

                    > #requestConfirmation {
                        align-self: center;
                        font-size: 12px;
                        margin-top: 8px;
                    }
                }

                > #buttons {
                    display: flex;
                    > :not(:first-child) {
                        margin-left: 16px;
                    }
                }
            }

            > #details {
                font-family: Rota-Medium;
                font-size: 16px;
                font-weight: 500;
                align-items: left;
                letter-spacing: 1px;
                line-height: 20px;
                margin-top: 30px;

                > :not(:first-child) {
                    margin-top: 16px;
                }
            }

            > .title {
                position: relative;
                margin-left: 28px;
                font-family: "Rota-MediumItalic";
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 25px;
                text-transform: uppercase;
                &::before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: -28px;
                    display: flex;
                    margin-right: 8px;
                    height: 1px;
                    width: 20px;
                    background-color: $primary;
                }
            }

            > table {
                margin-top: 20px;
                border-collapse: collapse;

                &#statusTable {
                    td {
                        align-items: center;
                        text-align: center;
                    }
                }

                &#productsTable {
                    th {
                        padding-bottom: 20px;
                        border-bottom: 1px solid #d1d1d1;
                        font-family: Rota-ExtraBold;
                        font-size: 12px;
                        font-weight: 800;
                        letter-spacing: 1.5px;
                        line-height: 14px;
                        text-transform: uppercase;
                        text-align: left;
                    }

                    .card {
                        display: flex;
                        flex-direction: column;
                    }

                    tr {
                        &.product-block {
                            td {
                                align-items: center;
                                text-align: left;
                                padding-top: 18px;
                                padding-bottom: 24px;
                                padding-right: 30px;
                                letter-spacing: 1px;
                                line-height: 22px;

                                &:nth-child(1) {
                                    width: 15%;
                                }

                                &:nth-child(2) {
                                    width: 25%;
                                }

                                &:nth-child(3) {
                                    width: 20%;
                                }

                                &:nth-child(4) {
                                    width: 20%;
                                }

                                &:nth-child(5) {
                                    width: 20%;
                                }

                                > .image-wrapper {
                                    position: relative;
                                    width: 86px;
                                    height: 86px;

                                    > .image {
                                        width: 100%;
                                        height: 100%;
                                        padding: 10px;
                                        border-radius: 50%;
                                        object-fit: contain;
                                        background-color: #ffffff;
                                    }

                                    .close-wrapper {
                                        position: absolute;
                                        top: -7px;
                                        right: -1px;
                                        width: 29px;
                                        height: 29px;
                                        border-radius: 50%;
                                        background-color: $dark;
                                    }
                                }
                                > .name {
                                    font-family: Rota-Italic;
                                    font-style: italic;
                                    letter-spacing: 1px;
                                    line-height: 24px;
                                    text-align: left;
                                    font-size: 16px;
                                }

                                > .reference {
                                    margin-top: 8px;
                                    color: $primary;
                                    font-family: Rota-ExtraBold;
                                    font-size: 12px;
                                    font-weight: 800;
                                    letter-spacing: 1.5px;
                                    line-height: 14px;
                                    text-transform: uppercase;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        flex-direction: column;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 80px;

        h1 {
            padding: 0;
        }

        #order {
            > .section {
                table#productsTable {
                    thead {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1px;
                        height: 1px;
                        margin: -1px;
                        border: 0;
                        clip: rect(0 0 0 0);
                    }

                    tbody {
                        tr {
                            &.product-block {
                                display: flex;
                                flex-direction: column;

                                td {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    border-bottom: none;
                                    text-align: right;

                                    &:nth-child(1),
                                    &:nth-child(2),
                                    &:nth-child(3),
                                    &:nth-child(4),
                                    &:nth-child(5) {
                                        width: 100%;
                                        padding-right: 0;
                                    }

                                    .reference {
                                        margin-left: 16px;
                                        text-align: right;
                                    }

                                    &::before {
                                        content: attr(data-label);
                                        font-family: Rota-ExtraBold;
                                        font-size: 12px;
                                        font-weight: 800;
                                        letter-spacing: 1.5px;
                                        line-height: 14px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                }

                &#addAndTotal {
                    flex-direction: column;

                    #totalTable {
                        align-self: flex-end;
                        margin-top: 16px;
                        table {
                            tbody {
                                tr {
                                    &.sub-total {
                                        :nth-child(2) {
                                            padding-left: 20px;
                                        }
                                    }

                                    &#total {
                                        td {
                                            &:nth-child(2) {
                                                padding-left: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &#requestAndButtons {
                    flex-direction: column;

                    #buttons {
                        margin-top: 32px;
                        flex-direction: column;
                        align-content: center;

                        .button {
                            margin-left: 0;
                            width: 100%;
                            margin-top: 16px;
                        }
                    }
                }
            }
        }

        .download-rectangle {
            width: 100%;
            margin-left: 0;
            margin-top: 36px;
        }
    }
}
