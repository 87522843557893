@import "src/colors.scss";

.recently-viewed-products {
    position: relative;
    padding-bottom: 70px;
    background-color: $light;

    .products-slider {
        margin-top:24px;
        margin-left:24px;
        margin-right:24px;
    }

    .wave {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70px;
    }
}
