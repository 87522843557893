@import "src/colors";
@import "src/fonts";

#chartComponent {
    width: calc(100% - 300px);
    @media screen and (max-width: 1080px) {
        margin-bottom: 20px;
        width: 100%;
    }
    .tick {
        fill: none;
        shape-rendering: crispEdges;
        stroke-width: 0.2px;
    }

    h2 {
        margin-bottom: 15px;
    }

    #doubleDropdownMenu {
        padding-bottom: 8px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        gap: 24px;

        > .menu {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-grow: 1;

            &#usersMenu,
            &#countriesMenu {
                align-self: flex-start;
            }
        }
    }

    #tooltip-container {
        padding: 20px;
        background: white;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        .label {
            font-family: Rota-Bold;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 16px;
            text-transform: uppercase;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
        }

        .line {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            gap: 16px;

            .value {
                font-family: Rota-Bold;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 16px;
            }
        }
    }

    #chartTitle {
        text-align: center;
        justify-content: center;
        font-family: Rota-Bold-Italic;
        font-size: 26px;
        font-style: italic;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 28px;
    }

    @media screen and (max-width: 840px) {
        #doubleDropdownMenu {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > .menu {
                margin-bottom: 24px;
            }
        }
    }
}
