@import "src/colors";
@import "src/fonts";

#news {
	background-color: $light;
	padding: 32px 72px;

	.title-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
		margin-bottom: 85px;
		margin-top: 20px;
    }

	.news-grid {
		display: grid;
		grid-template-areas:
				"news twitter"
				"news socials"
				"pages .";
		grid-template-columns: 2fr 1fr;
		grid-template-rows: 5fr 200px 120px;
		grid-gap: 24px;
		margin-top: 56px;
	}

	.news-section {
		grid-area: news;
	}

	.pages-section {
		grid-area: pages;
	}

	.twitter-section {
		margin-top: 24px;
		grid-area: twitter;
		border-radius: 20px;
		background-color: black;
		height: 600px;

		> div {
			height: 100%;

			> div {
				height: 100%;

				> iframe {
					border-radius: 20px;
				}
			}
		}
	}

	.socials-section {
		grid-area: socials;
	}

	.news-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.no-news {
		text-align: center;
		color: $dark;
		font-family: $regular;
		font-size: 24px;
	}

	.pager {
		margin-top: 26px;
		justify-content: center;
	}

	.bottom-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		h2 {
			color: $dark;
			font-family: $bold;
			font-size: 36px;
			letter-spacing: 0;
			line-height: 38px;
			margin-bottom: 20px;
		}

		.bottom-row-subtitle {
			color: #191919;
			font-family: $regular;
			font-size: 16px;
			letter-spacing: 1px;
			line-height: 22px;
		}

		.newsletter-subscribe {
			width: 45%;
		}

		.newsletter-subscribe-card {
			margin-top: 55px
		}

		.all-events-link {
			display: inline-block;
			color: $primary;
			font-family: $extra-bold;
			font-size: 12px;
			letter-spacing: 1.5px;
			line-height: 14px;
			text-transform: uppercase;
			padding-bottom: 3px;
			margin-top: 20px;
			border-bottom: 2px solid $primary;
			cursor: pointer;
			transition: padding-right 0.2s ease-in-out;
			margin-bottom: 32px;
		}

		.all-events-link:hover {
			padding-right: 4px;
		}
	}

	@media screen and (max-width: 768px) {
		& {
			padding: 32px 32px;
		}

		.pager {
			margin-top: 5px;
		}

		.news-grid {
			display: flex;
			flex-direction: column;
		}

		.twitter-section {
			margin-top: 0;
			height: 578px;
			min-height: 578px;
		}

		.socials-section {
			height: 200px;
		}

		.bottom-row {
			margin-top: 50px;
			justify-content: center;

			h2 {
				text-align: center;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		.news-grid {
			grid-template-columns: 1fr 1fr;
		}
	}

	@media screen and (max-width: 1080px) {
		.bottom-row {
			flex-direction: column;
			align-items: center;

			.newsletter-subscribe {
				width: 100%;
				margin-top: 40px;
			}

			h2, .bottom-row-subtitle {
				text-align: center;
			}

			.newsletter-subscribe-card {
				margin-top: 20px
			}
		}
	}
}