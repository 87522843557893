.language {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .language-text {
        align-self: center;
        font-family: Rota-bold;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 14px;
        text-transform: uppercase;
    }

    &.white {
        color: white;
    }
}
