@import "src/colors";

.stepper {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;

    .step {
        display: flex;
        margin: 4px;

        .caret {
            margin-right: 8px;
        }

        .label {
            display: flex;
            color: $gray;
            font-family: Rota-Medium;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 15px;
        }

        &.active {
            .label {
                color: $primary;
            }
        }

        &:first-child {
            .caret {
                display: none;
            }
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;

        .step {
            &:first-child {
                .caret {
                    display: block;
                }
            }
        }
    }
}
