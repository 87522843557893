@import "src/colors";
@import "src/fonts";

#tutorialDetails {
    background-color: $light;

    #content {
        display: flex;
        flex-direction: column;

        .page-title {
            margin-top: 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h1 {
                font-family: Rota-Bold;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 38px;
                display: flex;
            }

            .dropdown-input {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100px;

                .input {
                    .label {
                        width: 100%;
                        justify-content: space-between;

                        span {
                        }
                    }
                }
            }
        }

        .player-margins {
            margin: 40px 10% 40px 10%;

            .player-wrapper {
                position: relative;
                padding-top: 56.25%;

                .react-player {
                    position: absolute;
                    top: 0;

                    .react-player__preview {
                        .play-wrapper {
                            right: 0;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                            background-color: $primary;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .play {
                                margin-left: 7px;
                            }
                        }
                    }
                }
            }
        }

        .introduction {
            font-family: Rota-Medium;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 30px;
        }

        .button {
            margin-top: 70px;
            align-self: flex-start;
        }

        .content {
            margin-top: 80px;
            font-family: Rota-Regular;
        }

        .related-products {
            margin-top: 120px;

            h2 {
                font-family: Rota-Bold;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 38px;
            }

            .items {
                margin-top: 80px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;

                .item {
                    margin-bottom: 60px;
                }
            }
        }
    }
}
