@import "src/colors";

.back-link {
    align-self: flex-start;
    display: flex;
    align-items: center;
    border-bottom: none;

    .label {
        margin-left: 2px;
        padding-top: 3px;
        font-family: Rota-Medium;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 15px;
        text-transform: uppercase;

        &.white {
            color: white;
        }

        &.primary {
            color: $primary;
        }
    }
}
