@import "src/colors";
@import "src/fonts";

#users {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 80px;

    > h1 {
        margin-top: 36px;
    }

    > .users {
        display: flex;
        flex-wrap: wrap;
        margin: 54px -12px -22px;

        > .user {
            display: flex;
            justify-content: space-between;
            margin: 22px 12px;
            padding: 24px 54px 24px 56px;
            width: calc(50% - 24px);
            background-color: $primary;
            border-radius: 20px;

            > .info {
                > .line {
                    color: $white;
                    font-size: 14px;
                    letter-spacing: 1px;
                    line-height: 22px;

                    &:first-child {
                        margin-bottom: 16px;
                        font-family: Rota-Bold;
                    }

                    &:last-child {
                        margin-top: 16px;
                    }
                }
            }

            > .icon-link {
                align-self: flex-start;
                margin-top: 28px;
            }
        }
    }

    > .icon-link {
        margin-top: 38px;
    }

    @media screen and (max-width: 1080px) {
        > .users {
            > .user {
                flex-direction: column;
                padding: 18px 32px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;

        > h1,
        > .links {
            padding: 0;
        }

        > .users {
            > .user {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 480px) {
        padding-left: 16px;
        padding-right: 16px;

        > .users {
            > .user {
                padding: 12px 16px;
            }
        }
    }
}
