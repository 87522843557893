@import "src/colors";
@import "src/fonts";

.news-filter-button-wrapper {
    position: relative;
    display: inline-block;

    .news-filter-button-label {
        font-family: $regular;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }

    .news-filters-list {
        position: absolute;
        list-style-type: none;
        background-color: $dark;
        top: 68px;
        left: 0;
        width: 100%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        color: white;
        padding: 0;
        margin: 0;
    }

    .news-filters-list > li {
        padding: 13px 26px;
        font-family: $extra-bold;
        font-size: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
}

.news-filter-button-wrapper.open {
    .button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
