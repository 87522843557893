@import "src/colors.scss";

#productResourceItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 416px;
    cursor: pointer;

    > .image-content {
        align-items: flex-start;

        .image {
            width: 140px;
            height: 160px;
            border-radius: 20px;
        }
    }

    .text-content {
        margin-left: 24px;
        width: 307px;
        height: 100%;
        flex-direction: column;

        .name {
            font-family: Rota-Medium-Italic;
            font-size: 26px;
            font-style: italic;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 30px;
        }

        .excerpt {
            margin-top: 24px;
            margin-bottom: 40px;
            font-family: Rota-Regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 22px;
            overflow-wrap: break-word;
        }
    }

    @media screen and (max-width: 1080px) {
        flex-direction: column;
        .text-content {
            margin-left: 0;
            margin-top: 24px;
        }
    }
}
