@import "src/colors";

.caret-link {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 34px;
    border-radius: 24px;

    &.white {
        border: 1px solid white;
        color: white;

        .label {
            color: white;
        }
    }

    &.primary {
        border: 1px solid $primary;
        color: $primary;

        .label {
            color: $primary;
        }
    }

    .label {
        padding-top: 3px;
        color: $dark;
        font-family: Rota-ExtraBold;
        font-size: 12px;
        font-weight: 800;
        letter-spacing: 1.5px;
        line-height: 14px;
        text-transform: uppercase;
    }

    .caret {
        margin-left: 5px;
    }
}
