@import "src/colors";
@import "src/fonts";

.social-card {
	background-color: white;
	height: 200px;
	border-radius: 20px;

	.header {
		height: 92px;
		position: relative;
		overflow: hidden;

		.background {
			position: relative;
			height: 100%;
			background: url("/assets/images/home-header.png") center / cover;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: transparentize($color: $primary, $amount: 0.3);
				border-top-left-radius: 20px;
				border-top-right-radius: 20px;
			}
		}

		.wave {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 35%;
    		width: 120%;
		}
	}

	.social {
		padding: 0 24px 32px;

		& > span {
			color: $dark;
			font-family: $regular;
			font-size: 20px;
			font-style: italic;
			letter-spacing: 1px;
			line-height: 25px;
			text-transform: uppercase;
			margin-left: 26px;

			&::before {
				position: absolute;
				margin-top: 8px;
				margin-left: -26px;
				content: "";
				height: 1px;
				width: 20px;
				background-color: $primary;
			}
		}

		.links {
			display: flex;
			align-items: center;
			margin-top: 5px;

			a {
				flex: 0 0 auto;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background-color: $dark;

				&:not(:first-of-type) {
					margin-left: 8px;
				}

				@media screen and (max-width: 480px) {
					& {
						width: 40px;
						height: 40px;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}
	}
}