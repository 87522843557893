@import "src/colors.scss";

.notifications-popin-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;

    .notifications-popin {
        position: absolute;
        top: 82px;
        right: 72px;
        width: 361px;
        padding-top: 12px;
        border: 1px solid #e5e5e5;
        border-radius: 20px;
        background-color: $primary;
        box-shadow: 0 6px 12px 4px rgba(0, 0, 0, 0.03);
        z-index: 2;

        .section {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 28px 0;
        }

        .title {
            position: relative;
            color: #ffffff;
            font-family: Rota-MediumItalic;
            font-size: 20px;
            font-style: italic;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 25px;
            text-transform: uppercase;

            &::before {
                content: "";
                position: absolute;
                top: 10px;
                left: -28px;
                width: 20px;
                height: 1px;
                background-color: #ffffff;
            }
        }

        .notifications {
            border-bottom: 1px solid #e5e5e5;

            .notification {
                display: flex;
                align-items: center;
                padding: 22px 22px 22px 24px;
                border-top: 1px solid #e5e5e5;

                .icon {
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 48px;
                    width: 48px;
                    border-radius: 50%;
                    background-color: #ffffff;
                }

                .text {
                    margin-left: 17px;
                    font-family: Rota-Regular;
                    color: #ffffff;
                    font-size: 12px;
                    letter-spacing: 1px;
                    line-height: 16px;

                    strong {
                        font-family: Rota-Bold;
                    }
                }

                &.read {
                    .icon {
                        background-color: transparent;
                        border: 1px solid $gray;
                    }

                    .text {
                        color: $gray;

                        strong {
                            font-family: Rota-Regular;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            right: 0;
            width: 100%;
            border-radius: 0;
        }
    }
}
