@import "src/colors.scss";

#resourceItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 306px;
    cursor: pointer;

    > .itemContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .image-wrapper {
            position: relative;
            width: 100%;
            height: 306px;
            border-radius: 50%;
            background-color: #ffffff;

            .image {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: contain;
            }

            .play-wrapper {
                position: absolute;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: $primary;
                display: flex;
                justify-content: center;
                align-items: center;

                .play {
                    margin-left: 7px;
                }
            }

            .language-wrapper {
                position: absolute;
                top: 43px;
                right: 0px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: $dark;
            }
        }
        .name {
            margin-top: 8px;
            width: 100%;
            font-family: Rota-MediumItalic;
            font-size: 26px;
            font-style: italic;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 30px;
        }

        .excerpt {
            margin-top: 24px;
            font-family: Rota-Regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 22px;
        }
    }

    .button {
        margin-top: 40px;
    }
}
