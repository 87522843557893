@import "src/colors";

#order-validation {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 80px;
    background-color: $light;

    h1 {
        margin-top: 32px;
    }

    .subtitle {
        margin-top: 24px;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 22px;
    }

    > .content {
        display: flex;
        align-items: flex-start;
        margin-top: 80px;

        .products {
            flex: 1;
            display: flex;
            flex-direction: column;

            table {
                border-collapse: collapse;
                text-align: left;

                tr {
                    th,
                    td {
                        padding-right: 10px;

                        &:nth-child(1) {
                            width: 30%;
                        }

                        &:nth-child(2) {
                            width: 15%;
                        }

                        &:nth-child(3) {
                            width: 15%;
                        }

                        &:nth-child(4) {
                            width: 25%;
                        }

                        &:nth-child(5) {
                            width: 15%;
                        }
                    }
                }

                thead {
                    tr {
                        th {
                            padding-bottom: 20px;
                            border-bottom: 1px solid #d1d1d1;
                            font-family: Rota-ExtraBold;
                            font-size: 12px;
                            font-weight: 800;
                            letter-spacing: 1.5px;
                            line-height: 14px;
                            text-transform: uppercase;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding-top: 24px;
                            padding-bottom: 24px;
                            letter-spacing: 1px;
                            line-height: 22px;

                            &:not(.certificates) {
                                border-top: 1px solid #d1d1d1;
                            }

                            .product-block {
                                display: flex;
                                align-items: center;

                                .image-wrapper {
                                    position: relative;

                                    .image {
                                        width: 86px;
                                        height: 86px;
                                        padding: 10px;
                                        border-radius: 50%;
                                        object-fit: contain;
                                        background-color: #ffffff;
                                    }

                                    .close-wrapper {
                                        position: absolute;
                                        top: -7px;
                                        right: -1px;
                                        width: 29px;
                                        height: 29px;
                                        border-radius: 50%;
                                        background-color: $dark;
                                    }
                                }

                                .title {
                                    margin-left: 24px;

                                    .name {
                                        font-family: Rota-Italic;
                                        font-style: italic;
                                        letter-spacing: 1px;
                                        line-height: 24px;
                                    }

                                    .reference {
                                        margin-top: 8px;
                                        color: $primary;
                                        font-family: Rota-ExtraBold;
                                        font-size: 12px;
                                        font-weight: 800;
                                        letter-spacing: 1.5px;
                                        line-height: 14px;
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            &:nth-child(4) {
                                color: $primary;
                                font-family: Rota-Bold;
                                font-weight: bold;
                                letter-spacing: 1px;
                                line-height: 24px;
                            }

                            &:nth-child(5) {
                                font-family: Rota-ExtraBold;
                                font-weight: 800;
                                letter-spacing: 2px;
                                line-height: 20px;
                            }

                            &.certificates {
                                color: $dark;
                                font-size: 12px;
                                letter-spacing: 1px;
                                line-height: 18px;

                                > .content {
                                    position: relative;
                                    display: flex;
                                    padding-left: 138px;

                                    > .label {
                                        font-family: Rota-Bold;
                                        color: $primary;
                                    }

                                    > .list {
                                        margin-left: 5px;
                                    }

                                    &::before {
                                        content: "";
                                        position: absolute;
                                        top: 7px;
                                        left: 110px;
                                        height: 1px;
                                        width: 20px;
                                        background-color: $primary;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            td {
                                border-bottom: 1px solid #d1d1d1;
                            }
                        }
                    }
                }
            }

            .validation {
                align-self: flex-end;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 40px;

                .totals {
                    display: flex;
                    flex-direction: column;

                    .row {
                        .label {
                            width: 200px;
                            font-size: 12px;
                            letter-spacing: 1px;
                            line-height: 18px;
                        }

                        .value {
                            padding-left: 18px;
                            font-family: Rota-Bold;
                            font-size: 12px;
                            font-weight: bold;
                            letter-spacing: 1px;
                            line-height: 18px;
                        }

                        &.subtotal {
                            &:not(:first-child) {
                                margin-top: 24px;
                            }
                        }

                        &.total {
                            margin-top: 40px;

                            .label {
                                font-family: Rota-ExtraBold;
                                font-size: 12px;
                                font-weight: 800;
                                letter-spacing: 1.5px;
                                line-height: 14px;
                                text-transform: uppercase;
                            }

                            .value {
                                padding-left: 0;
                                color: $primary;
                                font-family: Rota-ExtraBold;
                                font-size: 24px;
                                font-weight: 800;
                                letter-spacing: 3px;
                                text-align: right;
                            }
                        }
                    }

                    .buttons {
                        align-self: flex-end;
                        display: flex;
                        flex-direction: row;
                        margin-top: 46px;

                        .button {
                            &:not(:first-child) {
                                margin-left: 16px;
                            }
                        }
                    }

                    > .info {
                        margin-top: 31px;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        line-height: 15px;
                    }
                }
            }
        }

        .summary {
            width: 360px;
            margin-left: 24px;
            padding: 48px 55px;
            border-radius: 20px;
            background-color: $dark;

            > .block {
                color: #ffffff;

                &:not(:first-child) {
                    margin-top: 40px;
                }

                > .title {
                    font-family: Rota-Bold;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 1.13px;
                    line-height: 22px;
                }

                > .content {
                    margin-top: 8px;
                    line-height: 24px;
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        > .content {
            flex-direction: column;
            align-items: stretch;

            .products {
                table {
                    thead {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1px;
                        height: 1px;
                        margin: -1px;
                        border: 0;
                        clip: rect(0 0 0 0);
                    }

                    tbody {
                        tr {
                            display: flex;
                            flex-direction: column;

                            td {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                border-bottom: none;
                                text-align: right;

                                &:nth-child(1),
                                &:nth-child(2),
                                &:nth-child(3),
                                &:nth-child(4),
                                &:nth-child(5) {
                                    width: 100%;
                                }

                                &::before {
                                    content: attr(data-label);
                                    font-family: Rota-ExtraBold;
                                    font-size: 12px;
                                    font-weight: 800;
                                    letter-spacing: 1.5px;
                                    line-height: 14px;
                                    text-transform: uppercase;
                                }

                                .product-block {
                                    margin-left: 10px;
                                }

                                &.certificates {
                                    > .content {
                                        padding-left: 28px;

                                        &::before {
                                            left: 0;
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                td {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }

            .summary {
                width: auto;
                margin-left: 0;
                margin-top: 64px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;

        > .content {
            .products {
                .validation {
                    .totals {
                        .buttons {
                            flex-direction: column;

                            .button {
                                &:not(:first-child) {
                                    margin-left: 0;
                                    margin-top: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}
