@import "src/colors";
@import "src/fonts";

#login {
    display: flex;
    flex-direction: column;
    padding-bottom: 72px;
    background-color: $light;

    .header {
        position: relative;
        height: 351px;

        .content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1;

            h1 {
                margin-top: 20px;
                color: #ffffff;
            }

            .signup {
                margin-top: 20px;
                font-size: 18px;
                letter-spacing: 1px;
                line-height: 26px;
                color: #ffffff;

                a {
                    margin-left: 4px;
                    white-space: nowrap;
                }
            }
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("/assets/images/home-header.png") center / cover;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($secondary, 0.6);
            }
        }

        .wave {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 120%;
            height: 80px;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        padding: 0 168px;

        .inputs {
            margin: 24px -12px -12px;
            display: flex;
            align-items: flex-end;

            > div {
                flex: 1;
                margin: 12px;
            }
        }

        .reset-password {
            align-self: flex-end;
            margin-top: 24px;
        }

        .submit-block {
            margin-top: 28px;
            align-self: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .message {
                margin-bottom: 32px;
            }

            .button {
                margin-top: 32px;
            }
        }
    }

    @media screen and (max-width: 1080px) {
        .form {
            padding: 0 72px;
        }
    }

    @media screen and (max-width: 768px) {
        .header {
            .content {
                h1 {
                    font-size: 32px;
                    line-height: 32px;
                }
            }
        }

        .form {
            padding: 0 32px;

            .inputs {
                flex-direction: column;
                align-items: stretch;
            }
        }
    }
}
