@import "src/colors";

.address-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 39px 0 55px;
    border-radius: 20px;
    background-color: $white;

    &:not(:first-child) {
        margin-top: 16px;
    }

    .title {
        display: flex;
        align-items: center;

        .name {
            margin-right: 20px;
            font-family: Rota-Bold;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1.13px;
            line-height: 22px;
        }

        .close {
            background-color: $dark;
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }

    .right {
        align-self: stretch;
        display: flex;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;

        .primary-label {
            margin-right: 16px;
            color: $primary;
            font-family: Rota-Bold;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 24px;
        }

        .caret {
            margin-bottom: 5px;
        }
    }
}
