@import "src/colors.scss";

.button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    border: none;
    font-family: Rota-ExtraBold;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
    height: 44px;
    padding: 3px 26px 0;

    &:hover {
        opacity: 0.5;
    }

    &.has-border {
        border: 1px solid $dark;

        &.dark,
        &.primary {
            border: 1px solid #ffffff;
        }
    }

    &.inactive,
    &.disabled {
        pointer-events: none;
    }

    &.disabled {
        background-color: $gray;
        color: $darkgray;
    }

    .icon {
        margin-left: 6px;
    }
}
