@import "src/colors";
@import "src/fonts";

.loyalty-status {
    position: relative;
	border: 1px solid #E5E5E5;
	border-radius: 20px;
	background-color: #F5FBFD;
    width: calc(100% / 4 - 20px);
    padding: 154px 24px 24px;

    .active-indicator {
        position: absolute;
        top: -115px;
        left: 0;
        width: 100%;
        text-align: center;
        color: $primary;
        font-family: $extra-bold;
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 25px;
        z-index: 1;

        &::after {
            display: block;
            content: "";
            height: 49px;
            width: 0;
            border-left: 1px solid $primary;
            margin: auto;
            margin-top: 16px;
        }
    }

	.top-box {
        background-color: $dark;
        border-radius: 20px;
        padding: 35px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        top: -50px;
        width: calc(100% - 30%);
        left: 15%;

        .partner-badge {
            width: 40px;
        }

        .loyalty-status-name {
            font-family: $bold;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 1.5px;
            margin: 10px 0;
        }
    }

    .advantages-title {
        position: relative;
        color: $dark;
        font-family: $regular;
        font-size: 20px;
        font-style: italic;
        letter-spacing: 1px;
        line-height: 25px;
        text-transform: uppercase;
        padding-left: 28px;

        &::before {
            display: block;
            content: "";
            height: 0px;
            width: 20px;
            position: absolute;
            border-bottom: 1px solid $primary;
            left: 0;
            top: 9px;
        }
    }

    .advantages {
        > div > ul {
            padding-left: 54px;

            > li {
                position: relative;
                color: $primary;
                font-family: $bold;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 20px;
                margin-bottom: 26px;

                &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: -54px;
                    width: 36px;
                    height: 36px;
                    background-image: url('/assets/icons/check-circle-primary.svg');
                    border-radius: 0;
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }
    }

    &.active {
        background-color: $primary;

        .advantages-title {
            color: white;

            &::before {
                border-color: white;
            }
        }

        .advantages {
            > div > ul > li {
                color: white;

                &::before {
                    background-image: url('/assets/icons/check-circle-white.svg');
                }
            }
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: calc(100% / 2 - 20px);
        
        &:nth-child(3), &:nth-child(4) {
            margin-top: 10em;
        }

        &:first-child.active ~ &:nth-child(3),
        &:nth-child(2).active ~ &:nth-child(3),
        &:first-child.active ~ &:nth-child(4),
        &:nth-child(2).active ~ &:nth-child(4) {
            margin-top: 5em;
        }
    }

    @media screen and (max-width: 767px) {
        width: 100%;

        &:not(:first-child) {
            margin-top: 5em;
        }

        &:not(:first-child).active {
            margin-top: 9em;
        }
    }
}