@import "src/colors";
@import "src/fonts";

.address-form {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 57px 55px 48px;
    border-radius: 20px;
    background-color: $dark;

    > .close-wrapper {
        position: absolute;
        top: 28px;
        right: 35px;
        padding: 20px;
        cursor: pointer;
    }

    .header {
        position: relative;
        display: flex;
        align-items: center;

        h2 {
            color: $white;
            font-family: Rota-BoldItalic;
            font-size: 26px;
            font-style: italic;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 28px;
        }

        .close {
            background-color: #f5fbfd;
            width: 30px;
            height: 30px;
            margin-left: 16px;
            border-radius: 50%;
        }

        .primary-label {
            margin-left: 16px;
            color: $primary;
            font-family: Rota-Bold;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 24px;
        }
    }

    > .form {
        margin-top: 28px;

        section {
            margin: 0 -12px -12px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            &:not(:first-child) {
                margin-top: 59px;
            }

            > div {
                width: calc(50% - 24px);
                margin: 12px;
            }

            .select {
                align-self: stretch;
            }

            > .title {
                margin-bottom: 40px;

                h2 {
                    color: #ffffff;
                    font-family: Rota-BoldItalic;
                    font-size: 26px;
                    font-style: italic;
                    font-weight: bold;
                    letter-spacing: 1px;
                    line-height: 28px;
                }
            }

            .inputs {
                margin: 24px -12px -12px;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;

                > div {
                    width: calc(50% - 24px);
                    margin: 12px;
                }

                .select {
                    align-self: stretch;
                }
            }
        }

        .check-input {
            margin-top: 40px;
        }

        .submit-block {
            margin-top: 48px;
            align-self: flex-start;

            .error {
                margin-top: 16px;
                color: red;
            }
        }

        .note {
            margin-top: 32px;
            color: #ffffff;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 16px;
        }
    }
}
