@import "src/colors";
@import "src/fonts";
@import "src/constants";

#shop {
    .title-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 64px 72px 130px;

        .title-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .download-catalog {
            display: flex;
            flex-direction: column;
            margin-top: 32px;

            .download-link {
                margin-top: 16px;
            }
        }

        .wave {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 90px;
        }
    }

    .search-dropdown {
        display: flex;
        flex-direction: column;
        padding: 2px;

        a {
            padding: 2px;
        }

        .category {
            display: flex;
            flex-direction: column;

            > a {
                &:first-child {
                    font-family: $bold;
                }
            }

            .sub-category {
                display: flex;
                flex-direction: column;

                .product {
                    padding-left: 20px;
                }
            }
        }
    }

    .products {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding: 0 72px 130px;
        background-color: $light;

        #products {
            position: absolute;
            top: calc(-16px - #{$header-height});
            left: 0;
        }

        .list {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin: 22px 0 0 20px;

            .items {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                // margin: 0 -5%;

                .product-item {
                    margin: 60px 3%;

                    &[role="presentation"] {
                        height: 1px;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .wave {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 70px;
        }
    }

    @media screen and (max-width: 1368px) {
        .products {
            padding: 0 32px 130px 48px;
        }
    }

    @media screen and (max-width: 1080px) {
        .title-wrapper {
            padding-bottom: 160px;

            .title-row {
                flex-direction: column;
                align-items: flex-start;

                .search-input {
                    margin-top: 32px;
                }
            }
        }

        .products {
            flex-direction: column;

            .list {
                margin: 22px 0 0;

                .items {
                    margin: 0 -3% -60px;
                    padding: 0;

                    .product-item {
                        margin: 60px 3%;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .title-wrapper {
            padding-left: 48px;
            padding-right: 48px;
        }

        .products {
            .list {
                align-self: stretch;

                .items {
                    flex-flow: column nowrap;
                    align-items: center;
                }
            }
        }
    }
}
