@import "src/colors";
@import "src/fonts";

#resources {
    background-color: $light;
    padding: 70px;
    .header {
        .title {
            font-family: Rota-Bold;
            font-size: 48px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 48px;
        }

        .introduction {
            margin-top: 48px;
            font-family: Rota-Regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 22px;
        }
    }

    .content {
        .highlight {
            margin-top: 100px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .highlight-image {
                width: 471px;
                height: 471px;
                border-radius: 50%;
            }

            .text-content {
                margin-left: 79px;
                align-self: center;

                .excerpt {
                    margin-top: 32px;
                    margin-bottom: 20px;
                }
            }
        }

        .academy {
            margin-top: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo {
                padding: 17px 28px;
                background-color: white;
                border-radius: 10px;
            }

            .excerpt {
                margin-top: 32px;
                margin-bottom: 20px;
            }
        }

        .other-categories {
            margin-top: 92px;
            display: flex;
            flex-direction: column;

            .categories-line {
                margin-top: 24px;
                display: flex;
                flex-direction: row;

                > :not(:first-child) {
                    margin-left: 24px;
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        .content {
            .highlight {
                .highlight-image {
                    width: 361px;
                    height: 361px;
                }
            }
            .other-categories {
                .categories-line {
                    flex-direction: column;
                    margin-top: 0;

                    > .category-card {
                        margin-left: 0;
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0;

        .content {
            .highlight {
                flex-direction: column;
                align-items: center;

                .text-content {
                    margin-left: 0;
                    margin-top: 24px;
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .content {
            .highlight {
                .highlight-image {
                    width: 280px;
                    height: 280px;
                }
            }
        }
    }
}
