@import "src/colors";
@import "src/fonts";

#agreements {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 80px;

    h1 {
        margin-top: 36px;
    }

    table {
        margin-top: 60px;
        border-collapse: collapse;

        th,
        td {
            padding: 24px 10px 24px 0;
        }

        th {
            font-family: Rota-ExtraBold;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 1.5px;
            line-height: 14px;
            text-align: left;
            text-transform: uppercase;
        }

        tbody {
            tr {
                td {
                    border-top: 1px solid #d1d1d1;

                    &:nth-child(2) {
                        font-family: Rota-ExtraBold;
                        font-size: 16px;
                        font-weight: 800;
                        letter-spacing: 1px;
                        line-height: 20px;
                    }

                    &:nth-child(5) {
                        padding-left: 16px;
                    }
                }
            }
        }
    }

    .request {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;

        > .button {
            margin-top: 24px;
        }

        .modal {
            padding: 32px;
            overflow: visible;

            > .title {
                margin-bottom: 16px;
                font-family: $bold;
            }

            > .buttons {
                display: flex;
                flex-wrap: wrap;
                margin: 32px -5px -5px;

                > .button {
                    margin: 5px;
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media screen and (max-width: 768px) {
        > h1,
        > .links {
            padding: 0;
        }

        table {
            thead {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 1px;
                margin: -1px;
                border: 0;
                clip: rect(0 0 0 0);
            }

            tbody {
                tr {
                    display: flex;
                    flex-direction: column;
                    padding: 12px 0;

                    &:not(:first-child) {
                        border-top: 1px solid #d1d1d1;
                    }

                    td {
                        border-top: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px 0;
                        text-align: right;

                        &::before {
                            content: attr(data-label);
                            font-family: Rota-ExtraBold;
                            font-size: 12px;
                            font-weight: 800;
                            letter-spacing: 1.5px;
                            line-height: 14px;
                            text-align: left;
                            text-transform: uppercase;
                        }

                        &:nth-child(5) {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}
