.quality-assurance {
    position: relative;
    display: flex;
    align-items: center;
    padding: 72px 72px 120px;

    .image {
        width: 471px;
        height: 471px;
        border-radius: 50%;
        object-fit: cover;
    }

    .content {
        margin-left: 79px;

        .title {
            margin-bottom: 30px;
            font-family: Rota-Bold;
            font-size: 48px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 48px;
        }

        .text {
            display: flex;
            align-items: flex-start;

            .left,
            .right {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .right {
                margin-left: 79px;

                .button {
                    margin-top: 50px;
                }
            }

            p {
                letter-spacing: 1px;
                line-height: 22px;
            }
        }
    }

    .wave {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
    }

    @media screen and (max-width: 1080px) {
        flex-direction: column;

        .content {
            margin: 72px 0 0;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 72px 24px 120px;
        
        .image {
            width: 306px;
            height: 306px;
        }

        .content {
            margin-top: 64px;

            .title {
                font-size: 36px;
            }

            .text {
                flex-direction: column;

                .right {
                    margin: 30px 0 0;

                    .button {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}
