@import "src/colors";
@import "src/fonts";

.autocomplete-text-input {
    position: relative;

    > .row {
        align-items: center;

        > .text-input {
            flex: 1;
        }

        > .close {
            width: 40px;
            height: 40px;
        }
    }

    .options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 100;

        .option-wrapper {
            padding: 8px 16px;
            cursor: pointer;

            .option-label {
                font-size: 14px;
                color: $white;
            }
        }
    }
}
