.round-rating {
    position: relative;
    width: 50px;
    height: 50px;

    .content {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .label {
            font-family: Rota-Medium;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1px;
        }
    }
}
