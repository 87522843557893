@import "src/colors";
@import "src/fonts";

.text-area-container {
    width: 100%;
    .label {
        text-transform: uppercase;
        font-family: Rota-Bold;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2.4px;
        line-height: 16px;
    }

    &.dark {
        border-bottom: 1px solid #ffffff;
        .label {
            color: #ffffff;
        }

        .text-area {
            background-color: #191817;
            color: #ffffff;
        }
    }

    .text-area {
        width: 100%;
        padding: 10px;
        background-color: #e0e5e7;
        border: none;
        border-radius: 20px;
        resize: none;
        font-family: Rota-Regular;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
    }
}
