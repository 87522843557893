@import "src/colors";

.semi-round {
    border-radius: 0 0 20px 20px !important;
    box-shadow: 10px 10px 10px 0 rgb(0 0 0 / 10%) !important;
}

.dropdown-input {
    position: relative;
    display: flex;

    .input {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .title {
            margin-right: 20px;
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 20px;
        }

        .label {
            display: flex;
            align-items: center;
            font-family: Rota-ExtraBold;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 2px;
            line-height: 18px;
            text-transform: uppercase;

            .caret {
                margin-left: 6px;
                margin-bottom: 6px;
            }
        }
    }

    .items {
        position: absolute;
        top: 100%;

        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        background-color: white;
        max-height: 200px;
        overflow-y: auto;
        color: black !important;
        border-radius: 20px;
        box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 10%);
        &::-webkit-scrollbar {
            display: none;
        }

        .item {
            padding: 16px;
            text-align: right;
            cursor: pointer;

            &:hover {
                background-color: #fcfcfc;
            }

            &:not(:first-child) {
                border-top: 1px solid $gray;
            }
        }
    }
}

.profile {
    .items {
        left: -20px;
        width: calc(100% + 40px);
    }
}
