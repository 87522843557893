@import "src/colors.scss";
@import "src/constants.scss";

#empty-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: $header-height;
    background-color: $light;
    z-index: 1;
}
