@import "src/colors";
@import "src/fonts";

.multi-select-autocomplete {
    display: flex;
    flex-direction: column;

    .values {
        display: flex;
        flex-wrap: wrap;
        margin: 20px -4px -4px;

        .value-wrapper {
            display: flex;
            align-items: center;
            height: 40px;
            margin: 4px;
            padding: 0 8px 0 16px;
            border: 1px solid $secondary;
            border-radius: 20px;

            .value-label {
                padding-top: 2px;
                font-size: 14px;
                color: $secondary;
            }

            > .close {
                width: 24px;
                height: 24px;
                margin-left: 10px;
            }
        }
    }
}
