@import "src/colors";
@import "src/fonts";

.news-card {
	background-color: white;
	border-radius: 20px;
	padding: 40px 55px;
	margin-top: 24px;
	width: 100%;

	.news-card-date {
		font-size: 12px;
		color: $primary;
		font-family: $extra-bold;
		letter-spacing: 1.5px;
  		line-height: 14px;
	}

	.news-card-title {
		color: $dark;
		font-family: $extra-bold;
		font-size: 20px;
		letter-spacing: 1px;
		line-height: 22px;
		margin-top: 8px;
		margin-bottom: 16px;
	}

	.news-card-excerpt {
		color: $dark;
		font-family: $regular;
		font-size: 16px;
		letter-spacing: 1px;
		line-height: 22px;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.read-news-link {
		display: inline-block;
		color: $primary;
		font-family: $extra-bold;
		font-size: 12px;
		letter-spacing: 1.5px;
		line-height: 14px;
		text-transform: uppercase;
		padding-bottom: 3px;
		margin-top: 20px;
		border-bottom: 2px solid $primary;
		cursor: pointer;
		transition: padding-right 0.2s ease-in-out;
	}

	.read-news-link:hover {
		padding-right: 4px;
	}

	@media screen and (min-width: 1025px) {
		& {
			max-width: calc(50% - 12px);
		}	
	}
}