@import "src/colors";

.pager {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 58px;

    .page-link {
        letter-spacing: 1px;

        &:not(:first-child) {
            margin-left: 24px;
        }

        &.active {
            font-family: Rota-ExtraBold;
            font-weight: 800;
            color: $primary;
            cursor: default;
            pointer-events: none;
        }
    }
}
