@import "src/colors";
@import "src/fonts";
@import "src/constants";

.resourceList {
    background-color: $light;

    .title-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;
    }

    .search-dropdown {
        display: flex;
        flex-direction: column;
        padding: 2px;

        a {
            padding: 2px;
        }

        .category {
            display: flex;
            flex-direction: column;

            > a {
                &:first-child {
                    font-family: $bold;
                }
            }

            .sub-category {
                display: flex;
                flex-direction: column;

                .product {
                    padding-left: 20px;
                }
            }
        }
    }
    .highlight {
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        .image {
            width: 471px;
            height: 471px;
            border-radius: 50%;
            background-color: #ffffff;
        }

        .text-content {
            margin-left: 70px;
            margin-top: 50px;
            align-self: center;

            .title {
                font-family: Rota-Medium-Italic;
                font-size: 36px;
                font-style: italic;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 38px;
            }
            .excerpt {
                margin-top: 24px;
                font-family: Rota-Regular;
                font-size: 16px;
                letter-spacing: 1px;
                line-height: 22px;
            }

            .button {
                margin-top: 24px;
            }
        }
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: row;

        .left-column {
            align-self: flex-start;

            .search-input {
                margin-top: 56px;
            }

            .sorter {
                margin-top: 60px;

                .dropdown-input {
                    margin-top: 16px;
                    margin-bottom: 16px;
                    align-items: center;
                    justify-content: center;
                    font-family: Rota-ExtraBold;
                    font-size: 14px;
                    font-weight: 800;
                    letter-spacing: 2px;
                    border: none;
                    text-transform: uppercase;
                    max-width: 252px;

                    .items {
                        background-color: $light;

                        .item {
                            color: $primary;
                        }
                    }
                }
            }
            .filters {
                margin-top: 40px;
                width: 252px;

                .dropdown-checkbox {
                    margin-top: 30px;
                    margin-bottom: 30px;
                    align-items: center;
                    justify-content: center;
                    font-family: Rota-ExtraBold;
                    font-size: 14px;
                    font-weight: 800;
                    letter-spacing: 2px;
                    border: none;
                    text-transform: uppercase;

                    .items {
                        background-color: $light;

                        .item {
                            color: $primary;
                        }
                    }
                }
            }
        }

        .list {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin: 150px 0 0 20px;

            .items {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                #resourceItem {
                    margin: 0px 20px 80px 20px;
                }
            }
        }

        .wave {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 70px;
        }
    }

    @media screen and (max-width: 1080px) {
        .title-wrapper {
            flex-direction: column;
            align-items: flex-start;
        }

        .links {
            flex-direction: column;
            align-items: flex-start;
        }

        .content {
            flex-direction: column;

            .left-column {
                justify-content: space-between;
                align-self: center;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                gap: 20px;
                margin-top: 40px;

                .search-input {
                    margin-top: 0;
                    height: fit-content;
                }

                .sorter {
                    margin-top: 0;
                    height: fit-content;
                }

                .filters {
                    margin-top: 0;
                    height: fit-content;
                }
                .link-text {
                    height: fit-content;
                    width: 252px;
                }
            }
            .list {
                margin: 22px 0 0;

                .items {
                    margin: 0 -3% -60px;
                    padding: 0;

                    .item {
                        margin: 60px 3%;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        #videosAndTutorial {
            .back-link {
                padding-left: 32px;
            }
        }

        .content {
            .left-column {
                margin-top: 0;
                padding: 40px;
            }
        }
    }
}
