@import "src/colors";

@import "src/fonts";

#product {
    display: flex;
    flex-direction: column;
    padding: 56px 72px 120px;
    background-color: $light;

    > .content {
        display: flex;
        flex-direction: row;
        margin-top: 36px;

        .presentation,
        .detail,
        .order {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .presentation {
            padding-top: 48px;

            .image-wrapper {
                position: relative;
                width: 306px;
                height: 306px;
                padding: 53px;
                border-radius: 50%;
                background-color: #ffffff;

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .categories {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 32px;

                .category {
                    align-self: flex-start;
                    display: flex;
                    align-items: center;
                    padding: 8px 16px;
                    border: 1px solid $secondary;
                    cursor: pointer;

                    &:not(:first-child) {
                        margin-top: 16px;
                    }

                    .label {
                        padding-top: 4px;
                        color: $secondary;
                        font-family: Rota-Medium;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        line-height: 15px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        > .main {
            margin-left: 79px;

            > .title {
                .price-row {
                    display: flex;
                    align-items: center;

                    > .price {
                        font-family: Rota-ExtraBold;
                        font-size: 24px;
                        font-weight: 800;
                        letter-spacing: 3px;
                        text-transform: uppercase;
                    }

                    .prices-tooltip {
                        display: flex;
                        flex-direction: column;
                        width: 360px;
                        margin-top: 53px;
                        padding: 32px 55px 48px;
                        background-color: $dark;
                        border-radius: 20px;
                        color: #ffffff;

                        > .title {
                            margin-bottom: 32px;
                            font-family: Rota-BoldItalic;
                            font-size: 26px;
                            font-style: italic;
                            font-weight: bold;
                            letter-spacing: 1px;
                            line-height: 28px;
                        }

                        > .formatted-text {
                            ul {
                                margin: 0;

                                li {
                                    > .price {
                                        font-family: Rota-Bold;
                                        font-weight: bold;
                                        letter-spacing: 1px;
                                        line-height: 24px;
                                    }

                                    &:not(:first-child) {
                                        margin-top: 8px;
                                    }
                                }
                            }
                        }
                    }

                    .vat {
                        margin-left: 10px;
                        padding-top: 3px;
                        font-size: 12px;
                        letter-spacing: 1.5px;
                        text-transform: uppercase;
                    }

                    .unavailable {
                        color: $secondary;
                        font-family: Rota-ExtraBold;
                        font-weight: 800;
                        letter-spacing: 2px;
                        line-height: 14px;
                        text-transform: uppercase;
                    }
                }

                .name {
                    margin-top: 24px;
                    font-family: Rota-MediumItalic;
                    font-size: 60px;
                    font-style: italic;
                    font-weight: 500;
                    letter-spacing: 2.31px;
                }
            }

            > .content {
                display: flex;
            }

            .detail {
                align-items: flex-start;

                .reference {
                    margin-top: 8px;
                    color: $primary;
                    font-family: Rota-ExtraBold;
                    font-size: 12px;
                    font-weight: 800;
                    letter-spacing: 1.5px;
                    line-height: 14px;
                    text-transform: uppercase;
                }

                h2 {
                    margin-top: 36px;
                    font-family: Rota-BoldItalic;
                    font-size: 26px;
                    font-style: italic;
                    font-weight: bold;
                    letter-spacing: 1px;
                    line-height: 28px;
                }

                .description,
                .technical {
                    margin-top: 32px;
                    letter-spacing: 1px;
                    line-height: 22px;
                }

                .download-sheet {
                    margin-top: 36px;
                }
            }

            .order {
                margin-left: 79px;
                padding-top: 60px;

                .kit-block {
                    margin-bottom: 68px;
                    padding: 36px 55px 0px;

                    h2 {
                        display: flex;
                        flex-flow: row wrap;
                        font-family: $bold;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        line-height: 20px;
                        margin-bottom: 16px;

                        .tooltip {
                            margin-left: 8px;
                        }
                    }
                    .dropdown-input {
                        padding: 16px 0;
                        border-bottom: 1px solid #e5e5e5;
                    }
                }

                .place-order-block {
                    padding: 36px 55px 41px;
                    border-radius: 20px;
                    background-color: $primary;

                    .cc-reward {
                        font-family: $regular;
                        font-size: 14px;
                        margin-top: 5px;
                        color: white;
                    }

                    .infinite-select {
                        border-bottom: 1px solid #e5e5e5;
                    }

                    .button {
                        margin-top: 32px;
                    }
                }

                .sales-rep {
                    padding: 32px;
                    background-color: $dark;
                    border-radius: 20px;
                    color: #ffffff;

                    .title {
                        font-family: Rota-BoldItalic;
                        font-size: 26px;
                        font-style: italic;
                        font-weight: bold;
                        letter-spacing: 1px;
                        line-height: 28px;
                    }

                    > .content {
                        margin-top: 24px;
                        letter-spacing: 1px;
                        line-height: 24px;
                    }

                    .picture {
                        margin-top: 20px;
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                    }

                    .contact {
                        display: flex;
                        flex-direction: column;
                        margin-top: 16px;
                        font-size: 14px;
                        letter-spacing: 1px;
                        line-height: 20px;

                        > img {
                            height: 70px;
                            width: 70px;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        > .info {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }

    .related-products {
        margin-top: 122px;
    }
    @media screen and (max-width: 1280px) {
        > .content {
            > .main {
                > .content {
                    .order {
                        .place-order-block {
                            .infinite-select {
                                flex-direction: column;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        padding: 56px 32px 120px;

        > .content {
            flex-direction: column;

            .presentation {
                padding-top: 0;
                flex: 1;
                display: flex;
                flex-direction: column;
            }

            > .main {
                margin-top: 64px;
                margin-left: 0;

                > .content {
                    flex-direction: column;

                    .detail {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                    }

                    .order {
                        margin-top: 64px;
                        margin-left: 0;
                        padding-top: 0;
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        .place-order-block {
                            .infinite-select {
                                flex-direction: row;
                            }
                        }
                    }
                }
            }
        }
    }
}
