@import "src/colors.scss";

.contacts {
    position: relative;
    display: flex;
    margin: 60px -12px 0;
    padding: 0 72px 130px;

    .contact {
        margin: 12px;
        width: calc(25% - 24px);
        display: flex;
        flex-direction: column;
        padding: 55px 40px;
        border-radius: 20px;
        background-color: $light;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 22px;

        .content {
            flex: 1;

            p {
                margin-top: 16px;

                .subtitle {
                    font-family: Rota-Bold;
                    color: $primary;
                }
            }
        }

        .link {
            margin-top: 30px;

            &.more-info {
                display: flex;
                align-items: center;
                height: 40px;
            }
        }

        &.preferred {
            background-color: $dark;
            color: #ffffff;
            line-height: 20px;

            .title {
                color: #ffffff;
            }

            .content {
                flex: 1;

                p {
                    margin-top: 0;
                }

                img {
                    margin: 32px 0 18px;
                    height: 70px;
                    width: 70px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }
    }

    .wave {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70px;
    }
}

@media screen and (max-width: 1368px) {
    .contacts {
        .contact {
            padding: 28px 20px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .contacts {
        flex-wrap: wrap;

        .contact {
            width: calc(50% - 24px);
        }
    }
}

@media screen and (max-width: 768px) {
    .contacts {
        flex-direction: column;

        .contact {
            width: calc(100% - 24px);
        }
    }
}
