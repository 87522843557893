@import "src/colors";

.radio-input {
    position: relative;
    display: flex;
    flex-direction: column;

    .label {
        margin-bottom: 12px;
        font-family: Rota-Bold;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2.4px;
        line-height: 16px;
        text-transform: uppercase;
    }

    .sublabel {
        margin: 12px 0 15px;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 22px;
    }

    .items {
        display: flex;

        .item {
            position: relative;
            padding-left: 22px;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 16px;
            cursor: pointer;

            &:not(:first-child) {
                margin-left: 42px;
            }

            &::before {
                content: "";
                position: absolute;
                top: -1px;
                left: 0;
                width: 12px;
                height: 12px;
                border: 1px solid $primary;
                border-radius: 50%;
            }

            &.active {
                &::before {
                    background-color: $primary;
                }
            }
        }
    }

    &.vertical {
        .items {
            flex-direction: column;

            .item {
                &:not(:first-child) {
                    margin-left: 0;
                    margin-top: 16px;
                }
            }
        }
    }

    &.white {
        .label {
            color: $white;
        }

        .items {
            .item {
                color: $white;

                &::before {
                    border: 1px solid $white;
                }

                &.active {
                    &::before {
                        background-color: $white;
                    }
                }
            }
        }
    }

    &.primary {
        .label {
            color: $primary;
            font-family: Rota-ExtraBold;
            font-size: 20px;
            font-weight: 800;
            letter-spacing: 1px;
            line-height: 25px;
            text-transform: none;
        }
    }

    &.multiple {
        .items {
            flex-direction: column;

            .item {
                &:not(:first-child) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .items {
            flex-direction: column;

            .item {
                &:not(:first-child) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }
}
