@import "src/colors";

.search-input {
    position: relative;
    width: 252px;
    border-bottom: 1px solid $primary;

    input {
        width: calc(100% - 30px);
        font-family: Rota-Bold;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 24px;
        color: $primary;
    }

    &.primary {
        ::placeholder {
            color: $primary;
        }
    }

    .search-icon {
        position: absolute;
        bottom: 0;
        right: -8px;
    }

    .dropdown {
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        width: 100%;
        z-index: 100;
        background-color: #ffffff;
        border-radius: 0 0 20px 20px;
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
        padding: 10px;
        max-height: 300px;
        overflow-y: auto;
    }
}
