@import "src/colors";
@import "src/fonts";

.carecoins-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px;

    > .title {
        font-family: $bold;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 38px;
        text-align: center;
    }

    .available {
        margin-top: 24px;

        > .value {
            font-family: $bold;
        }
    }

    .number-input {
        margin-top: 24px;
        width: 200px;
    }

    .dollar-value {
        margin-top: 8px;
        font-family: $bold;
    }

    .new-total {
        margin-top: 24px;
        text-align: center;

        > .value {
            padding-left: 10px;
            font-family: $extra-bold;
            color: $primary;
            font-size: 24px;
            font-weight: 800;
            letter-spacing: 3px;
        }
    }

    .buttons {
        display: flex;
        margin-top: 32px;

        > .button {
            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 32px;

        > .title {
            font-size: 24px;
            line-height: 24px;
        }

        .buttons {
            flex-direction: column;

            > .button {
                &:not(:first-child) {
                    margin-left: 0;
                    margin-top: 16px;
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        > .title {
            font-size: 16px;
            line-height: 16px;
        }
    }
}
