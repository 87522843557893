@import "src/colors";
@import "src/fonts";

#my-womancare {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 80px;

    h1 {
        margin-top: 32px;
    }

    .services {
        display: flex;
        flex-wrap: wrap;
        margin: 75px -12px -12px;

        .service-block {
            display: flex;
            flex-direction: column;
            margin: 12px;
            width: calc(100% / 3 - 24px);
            padding: 55px;
            border: 1px solid #e5e5e5;
            border-radius: 20px;
            background-color: $light;

            > .title {
                position: relative;
                margin-left: 28px;
                font-family: "Rota-MediumItalic";
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 25px;
                text-transform: uppercase;

                &::before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: -28px;
                    display: flex;
                    margin-right: 8px;
                    height: 1px;
                    width: 20px;
                    background-color: $primary;
                }
            }

            > .content {
                > .description {
                    margin-top: 34px;
                    margin-bottom: 16px;
                }

                > p {
                    font-family: Rota-ExtraBold;
                    font-size: 16px;
                    font-weight: 800;
                    letter-spacing: 1px;
                    line-height: 20px;
                }

                > .links {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    > .download {
                        margin-bottom: 28px;
                    }

                    > .link-button {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }

    .current-partner-level-wrapper {
        background-color: #191919;
        border-radius: 20px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .partner-badge {
            width: 40px;
        }

        .partner-level-name {
            font-family: $bold;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1.5px;
            margin: 10px 0;
            color: $primary;
            text-align: center;
        }
    }

    @media screen and (max-width: 1080px) {
        .services {
            .service-block {
                width: calc(100% / 2 - 24px);
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 32px 32px 80px;

        .services {
            .service-block {
                width: 100%;
                padding: 32px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        padding: 32px 16px 80px;

        .services {
            .service-block {
                width: 100%;
                padding: 32px;
            }
        }
    }
}
