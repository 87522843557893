@import "src/colors";

.tooltip {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid $dark;
    border-radius: 50%;
    z-index: 9;
    cursor: pointer;

    &::after {
        content: "i";
        position: absolute;
        top: 2px;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content {
        position: absolute;
        top: 24px;
        left: 24px;
        width: 200px;
        padding: 2px 4px;
        font-family: Rota-Regular;
        background-color: rgba(0, 0, 0, 0.8);
        color: #ffffff;
        z-index: 100;
    }

    &.dark {
        border: 1px solid #ffffff;
    }

    &.primary {
        border: 1px solid $primary;

        &::after {
            color: $primary;
        }
    }

    &.secondary {
        border: 1px solid $secondary;

        &::after {
            color: $secondary;
        }
    }
}
