@import "src/colors.scss";

#academyResourceItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 637px;
    cursor: pointer;

    > .image-content {
        align-items: flex-start;

        .image-wrapper {
            position: relative;
            width: 100%;
            height: 306px;
            width: 306px;
            border-radius: 50%;
            background-color: #ffffff;

            .image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 50%;
            }
        }
    }

    .text-content {
        margin-left: 24px;
        width: 307px;
        .name {
            font-family: Rota-Medium-Italic;
            font-size: 26px;
            font-style: italic;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 30px;
        }

        .excerpt {
            margin-top: 24px;
            font-family: Rota-Regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 22px;
            overflow-wrap: break-word;
        }
    }

    .button {
        margin-top: 40px;
    }

    @media screen and (max-width: 1080px) {
        width: calc(100vw - 100px);
        flex-direction: column;
        align-items: center;
        .text-content {
            margin-left: 0;
            margin-top: 24px;
        }
    }
}
