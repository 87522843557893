@import "src/colors.scss";

#footer {
    background-color: $light;
    border-top: 1px solid #e5e5e5;
    font-family: "Rota-Medium";
    line-height: 20px;

    .top,
    .bottom {
        display: flex;
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 124px;
        padding: 0 72px;

        .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .logo {
                flex: 0 0 auto;
                width: 340px;
                height: 78px;
                object-fit: contain;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .menu {
                align-self: stretch;
                display: flex;
                margin-left: 110px;

                a {
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    white-space: nowrap;
                }
            }
        }

        .social {
            display: flex;
            align-items: center;

            .links {
                display: flex;
                align-items: center;
                margin-left: 30px;

                a {
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: $dark;

                    &:not(:first-of-type) {
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .bottom {
        padding: 32px 0 141px 402px;
        border-top: 1px solid #e5e5e5;

        > :not(:first-child) {
            margin-left: 45px;
        }
    }

    @media screen and (max-width: 1368px) {
        .top {
            flex-direction: column;
            height: auto;
            padding: 24px 0;

            .left {
                flex-direction: column;

                .menu {
                    margin-left: 0;

                    a {
                        padding: 24px 20px;
                    }
                }
            }

            .social {
                margin-top: 16px;
            }
        }

        .bottom {
            justify-content: center;
            padding-left: 0;
        }
    }

    @media screen and (max-width: 768px) {
        .top {
            .left {
                align-self: stretch;

                .menu {
                    align-self: stretch;
                    flex-direction: column;
                    margin-top: 20px;

                    a {
                        display: flex;
                        justify-content: center;
                        padding: 12px;
                    }
                }
            }

            .social {
                flex-direction: column;
                margin-top: 12px;

                .links {
                    margin-left: 0;
                    margin-top: 24px;
                }
            }
        }

        .bottom {
            flex-direction: column;
            align-items: center;

            > :not(:first-child) {
                margin: 24px 0 0;
            }
        }
    }
}
