@import "src/colors.scss";

.notifications-icon {
    position: relative;

    svg {
        display: block;
    }

    .badge {
        position: absolute;
        top: 4px;
        right: 4px;
        height: 14px;
        min-width: 14px;
        padding: 2px 4px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        background-color: $primary;
        color: #ffffff;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 12px;
    }
}
