@import "src/colors";

.status-link {
    display: flex;
    align-items: center;

    img,
    > svg {
        margin-right: 7px;
    }

    > .status-link-text {
        display: inline-flex;
        color: $secondary;
        font-family: Rota-Medium;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 16px;
    }
}
