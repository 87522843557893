@import "src/colors.scss";
@import "src/constants.scss";

#header {
    position: fixed;
    top: 0;
    width: 100%;

    height: $header-height;
    background-color: #ffffff;
    // border-bottom: 1px solid #e5e5e5;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 5%);
    font-family: "Rota-Medium";
    z-index: 10;

    .header-content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        z-index: 2;
        padding-top: 24px;
    }

    .logo {
        flex: 0 0 auto;
        width: 340px;
        height: 78px;
        object-fit: contain;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .tab {
        display: flex;
        align-items: center;
        padding: 0 24px;
        line-height: 20px;
        text-align: center;
        white-space: nowrap;

        > span {
            cursor: pointer;
        }

        &.emph {
            font-family: "Rota-ExtraBold";
            color: $primary;
        }

        &.current {
            .title {
                border-bottom: 2px solid $primary;
            }
        }
    }

    .menu {
        align-self: stretch;
        display: flex;

        .tabs {
            display: flex;

            .tab {
                img {
                    margin-left: 6px;
                }
            }
        }

        .icon-links {
            align-self: center;
            display: flex;
            margin-left: 35px;

            > span {
                cursor: pointer;
            }
        }
    }

    .submenu {
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        width: 100%;
        z-index: 1;
        display: flex;
        height: 104px;
        overflow-x: auto;
        background-color: $light;
        box-shadow: 5px 5px 10px rgb(0 0 0 / 10%);

        .tabs {
            display: flex;
            justify-content: flex-start;
            padding: 0 24px;

            .tab {
                padding: 0 30px;
            }
        }
    }
}

@media screen and (max-width: 1450px) {
    #header {
        .menu {
            .tab {
                padding: 0 12px;
            }
        }

        .submenu {
            .tabs {
                .tab {
                    padding: 0 24px;
                }
            }
        }
    }
}

@media screen and (max-width: 1220px) {
    #header {
        position: relative;
        flex-direction: column;
        height: auto;

        .menu {
            align-self: center;
            flex-direction: column;

            .icon-links {
                margin: 12px 0 24px;
            }
        }

        .submenu {
            flex-direction: column;
            height: auto;

            .tabs {
                flex-direction: column;
                align-items: stretch;

                .tab {
                    justify-content: center;
                    padding: 12px 0;

                    &:first-child {
                        margin-top: 12px;
                    }

                    &:last-child {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #header {
        .menu {
            align-self: stretch;
            height: auto;
            padding-bottom: 12px;

            .tabs {
                flex-direction: column;

                .tab {
                    justify-content: center;
                    padding: 12px 0;

                    img {
                        display: none;
                    }
                }
            }
        }
    }
}
