@import "src/colors";

#cart {
    display: flex;
    flex-direction: column;
    padding: 32px 72px 160px;
    background-color: $light;

    h1 {
        margin-top: 32px;
    }

    .content {
        display: flex;
        align-items: flex-start;
        margin-top: 48px;

        > .sections {
            flex: 1;

            .dropdown-section {
                &:not(:first-child) {
                    margin-top: 98px;
                }

                .products {
                    display: flex;
                    flex-direction: column;
                    margin-top: 32px;

                    table {
                        border-collapse: collapse;
                        text-align: left;

                        tr {
                            th,
                            td {
                                padding-right: 10px;

                                &:nth-child(1) {
                                    width: 30%;
                                }

                                &:nth-child(2) {
                                    width: 15%;
                                }

                                &:nth-child(3) {
                                    width: 15%;
                                }

                                &:nth-child(4) {
                                    width: 25%;
                                }

                                &:nth-child(5) {
                                    width: 15%;
                                }
                            }
                        }

                        thead {
                            tr {
                                th {
                                    padding-bottom: 20px;
                                    border-bottom: 1px solid #d1d1d1;
                                    font-family: Rota-ExtraBold;
                                    font-size: 12px;
                                    font-weight: 800;
                                    letter-spacing: 1.5px;
                                    line-height: 14px;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    padding-top: 24px;
                                    padding-bottom: 16px;
                                    border-bottom: 1px solid #d1d1d1;
                                    letter-spacing: 1px;
                                    line-height: 22px;

                                    .product-block {
                                        display: flex;
                                        align-items: center;

                                        .image-wrapper {
                                            position: relative;

                                            .image {
                                                width: 86px;
                                                height: 86px;
                                                padding: 10px;
                                                border-radius: 50%;
                                                object-fit: contain;
                                                background-color: #ffffff;
                                            }

                                            .close-wrapper {
                                                position: absolute;
                                                top: -7px;
                                                right: -1px;
                                                width: 29px;
                                                height: 29px;
                                                border-radius: 50%;
                                                background-color: $dark;
                                            }
                                        }

                                        .title {
                                            margin-left: 24px;

                                            .name {
                                                font-family: Rota-Italic;
                                                font-style: italic;
                                                letter-spacing: 1px;
                                                line-height: 24px;
                                            }

                                            .reference {
                                                margin-top: 8px;
                                                color: $primary;
                                                font-family: Rota-ExtraBold;
                                                font-size: 12px;
                                                font-weight: 800;
                                                letter-spacing: 1.5px;
                                                line-height: 14px;
                                                text-transform: uppercase;
                                            }
                                        }
                                    }

                                    &:nth-child(5) {
                                        font-family: Rota-ExtraBold;
                                        font-weight: 800;
                                        letter-spacing: 2px;
                                        line-height: 20px;
                                    }

                                    .select {
                                        width: 90%;
                                    }
                                }
                            }
                        }
                    }

                    .validation {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        margin-top: 40px;

                        .totals {
                            display: flex;
                            flex-direction: column;
                            margin-left: 24px;

                            .row {
                                .label {
                                    width: 200px;
                                    font-size: 12px;
                                    letter-spacing: 1px;
                                    line-height: 18px;
                                }

                                .value {
                                    padding-left: 18px;
                                    font-family: Rota-Bold;
                                    font-size: 12px;
                                    font-weight: bold;
                                    letter-spacing: 1px;
                                    line-height: 18px;
                                }

                                &.total {
                                    align-self: flex-end;
                                    margin-top: 40px;

                                    .label {
                                        font-family: Rota-ExtraBold;
                                        font-size: 12px;
                                        font-weight: 800;
                                        letter-spacing: 1.5px;
                                        line-height: 14px;
                                        text-transform: uppercase;
                                    }

                                    .value {
                                        padding-left: 0;
                                        color: $primary;
                                        font-family: Rota-ExtraBold;
                                        font-size: 24px;
                                        font-weight: 800;
                                        letter-spacing: 3px;
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }

                    .buttons {
                        display: flex;
                        flex-direction: row-reverse;
                        align-self: flex-end;
                        margin-top: 46px;

                        .button {
                            margin: 8px;
                        }
                    }

                    > .radio-input {
                        width: 50%;
                        margin-top: 40px;
                    }
                }
            }
        }
    }

    .summary {
        display: flex;
        flex-direction: column;
        width: 360px;
        margin-left: 24px;
        padding: 36px 55px 40px;
        border-radius: 20px;
        background-color: #ffffff;

        h2 {
            font-family: Rota-Bold;
            font-size: 36px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 38px;
        }

        > .sections {
            margin-top: 32px;
            .section {
                &:not(:first-child) {
                    margin-top: 24px;
                }

                > .title {
                    color: $secondary;
                    font-family: Rota-Bold;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    line-height: 20px;
                }

                > .products {
                    margin-top: 16px;
                    border-top: 1px solid #d1d1d1;

                    .product {
                        padding: 4px 0;
                        border-bottom: 1px solid #d1d1d1;

                        .name {
                            font-family: Rota-Italic;
                            font-style: italic;
                            letter-spacing: 1px;
                            line-height: 24px;
                        }

                        .row {
                            justify-content: space-between;

                            .title {
                                color: $gray;
                                font-size: 12px;
                                letter-spacing: 1px;
                                line-height: 16px;
                            }

                            .value {
                                font-family: Rota-Bold;
                                font-size: 12px;
                                font-weight: bold;
                                letter-spacing: 1px;
                                line-height: 18px;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }

        .total {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 28px;

            .title {
                color: $primary;
                font-family: Rota-ExtraBold;
                font-size: 12px;
                font-weight: 800;
                letter-spacing: 1.5px;
                line-height: 14px;
            }

            .value {
                color: $primary;
                font-family: Rota-ExtraBold;
                font-size: 14px;
                font-weight: 800;
                letter-spacing: 1px;
                line-height: 20px;
                text-align: right;
            }
        }
    }

    @media screen and (max-width: 1368px) {
        padding-left: 32px;
        padding-right: 32px;

        .summary {
            width: 320px;
            padding: 24px 24px 32px;

            > .sections {
                margin-top: 24px;
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .content {
            > .sections {
                .dropdown-section {
                    .products {
                        table {
                            tr {
                                th,
                                td {
                                    &:nth-child(4) {
                                        width: 20%;

                                        > .infinite-select {
                                            min-width: 120px;
                                        }
                                    }

                                    &:nth-child(5) {
                                        width: 20%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        .content {
            flex-direction: column;
            align-items: stretch;

            > .sections {
                .dropdown-section {
                    .products {
                        table {
                            thead {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 1px;
                                height: 1px;
                                margin: -1px;
                                border: 0;
                                clip: rect(0 0 0 0);
                            }

                            tbody {
                                tr {
                                    display: flex;
                                    flex-direction: column;

                                    &:not(:first-child) {
                                        border-top: 1px solid #d1d1d1;
                                    }

                                    td {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        padding-right: 0;
                                        border-bottom: none;
                                        text-align: right;

                                        &:nth-child(1),
                                        &:nth-child(2),
                                        &:nth-child(3),
                                        &:nth-child(4),
                                        &:nth-child(5) {
                                            width: 100%;
                                        }

                                        &:nth-child(4) {
                                            > .infinite-select {
                                                min-width: 150px;
                                            }
                                        }

                                        &::before {
                                            content: attr(data-label);
                                            font-family: Rota-ExtraBold;
                                            font-size: 12px;
                                            font-weight: 800;
                                            letter-spacing: 1.5px;
                                            line-height: 14px;
                                            text-transform: uppercase;
                                        }

                                        .product-block {
                                            margin-left: 10px;
                                        }

                                        .select {
                                            width: 50%;
                                        }
                                    }
                                }
                            }
                        }

                        .validation {
                            .totals {
                                .row {
                                    .label {
                                        width: 140px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .summary {
            width: auto;
            margin-left: 0;
            margin-top: 98px;
        }
    }

    @media screen and (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;

        .content {
            > .sections {
                .dropdown-section {
                    .products {
                        .validation {
                            flex-direction: column;

                            .totals {
                                align-self: flex-end;
                                margin-left: 0;
                                margin-top: 40px;
                            }
                        }

                        > .radio-input {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .content {
            flex-direction: column;
            align-items: stretch;

            > .sections {
                .dropdown-section {
                    > .title {
                        padding: 0 24px;
                    }

                    .products {
                        .buttons {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
}
