@import "src/colors";
@import "src/fonts";

#events {
	background-color: $light;
	padding: 32px 72px;

	.title-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
		margin-bottom: 85px;
		margin-top: 20px;
    }

	.events-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 79px;
	}

	.no-events {
		text-align: center;
		color: $dark;
		font-family: $regular;
		font-size: 24px;
	}

	.pager {
		margin-top: 26px;
		justify-content: center;
	}

	.bottom-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		h2 {
			color: $dark;
			font-family: $bold;
			font-size: 36px;
			letter-spacing: 0;
			line-height: 38px;
		}
	}

	@media screen and (max-width: 768px) {
		& {
			padding: 32px 32px;
		}
	}

	@media screen and (max-width: 1024px) {
		.events-grid {
			grid-template-columns: 1fr;
		}
	}
}