.confirmation-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;

    .buttons {
        display: flex;
        margin-top: 24px;

        > :not(:first-child) {
            margin-left: 10px;
        }
    }
}
