@import "src/colors";
@import "src/fonts";

.select {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid $primary;

    .title {
        font-family: $bold;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2.4px;
        line-height: 16px;
        text-transform: uppercase;
        white-space: nowrap;
    }

    .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        color: $primary;
        font-family: Rota-Bold;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 24px;
        cursor: pointer;
        span {
            max-height: 35px;
            overflow: hidden;
        }
        .caret {
            margin-left: 6px;
            margin-bottom: 6px;
        }
    }

    .options {
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 100;
        background-color: #ffffff;
        max-height: 200px;
        overflow-y: auto;

        .option {
            padding: 16px;
            cursor: pointer;
        }
    }

    &.white {
        border-bottom: 1px solid $white;

        .title {
            color: $white;
        }

        .label {
            color: $white;
        }
    }

    &.secondary {
        border-bottom: 1px solid $secondary;

        .label {
            color: $dark;
            font-family: Rota-Regular;
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 20px;
        }
    }
}
