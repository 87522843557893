@import "src/colors.scss";

#landing {
    display: flex;
    flex-direction: column;

    .header {
        position: relative;

        .background {
            position: relative;
            height: 300px;
            background: url("/assets/images/home-header.png") center / cover;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.8);
            }
        }

        .wave {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80px;
        }

        .title-block {
            position: absolute;
            top: 72px;
            left: 72px;
            font-size: 36px;

            .title {
                font-family: Rota-Bold;

                .name {
                    color: $primary;
                }
            }

            .subtitle {
                margin-top: -5px;
                font-family: Rota-Italic;
            }
        }
    }
}
