@import "src/colors";

#order-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 64px 80px;
    background-color: $light;

    > img {
        margin-top: 16px;
        width: 70px;
        height: 70px;
        object-fit: contain;
    }

    h1 {
        margin-top: 24px;
        text-align: center;
    }

    .subtitle {
        width: 80%;
        margin-top: 24px;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 26px;
        text-align: center;
    }

    > .order {
        align-self: stretch;
        display: flex;
        margin-top: 85px;

        > .block {
            flex: 1;
            display: flex;
            justify-content: center;
            padding: 48px 16px 21px;

            > .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                > .title {
                    color: $primary;
                    font-family: Rota-ExtraBold;
                    font-weight: 800;
                    letter-spacing: 1px;
                    line-height: 20px;
                }

                > .button-link {
                    margin-top: 34px;
                }
            }

            &:nth-child(2) {
                border-left: 1px solid $gray;
            }
        }
    }

    > .button-link {
        margin-top: 41px;
    }

    @media screen and (max-width: 1080px) {
        padding: 64px 32px 80px;
    }

    @media screen and (max-width: 768px) {
        > .order {
            flex-direction: column;
            margin-top: 32px;

            > .block {
                padding: 32px 0;

                > .content {
                    align-items: center;

                    > .title {
                        text-align: center;
                    }
                }

                &:nth-child(2) {
                    border-left: none;
                    border-top: 1px solid $gray;
                }
            }
        }
    }
}
