@import "src/colors.scss";

.wcacademy-slider {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $light;

    .slider-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .title {
            font-family: Rota-Bold;
            font-size: 36px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 38px;
        }

        .arrows {
            right: 0;
            display: flex;

            .arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 50px;
                border: 1px solid $dark;
                border-radius: 50%;

                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }
    }

    .see-all {
        margin-top: 30px;
    }

    .slider-container {
        align-self: stretch;
        display: flex;
        margin-top: 55px;
        overflow: hidden;

        .slider {
            display: flex;

            #academyResourceItem {
                margin-right: 33px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .slider-header {
            flex-direction: column;
        }
    }
}
