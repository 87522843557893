@import "src/colors";
@import "src/fonts";

#womanCareAcademyDetails {
    background-color: $light;
    padding: 30px 70px;

    > .header {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }

        .text-content {
            flex: 0 1 auto;

            .name {
                font-family: Rota-Bold;
                font-size: 48px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 48px;
            }

            .category {
                margin-top: 10px;
                font-family: Rota-ExtraBold;
                font-size: 12px;
                font-weight: 800;
                letter-spacing: 1.5px;
                line-height: 14px;
                color: $primary;
                text-transform: uppercase;
            }

            .excerpt {
                margin-top: 48px;
                font-family: Rota-Regular;
                font-size: 16px;
                letter-spacing: 1px;
                line-height: 22px;
                overflow-wrap: break-word;
            }
        }

        > .image-content {
            align-items: flex-start;

            .image-wrapper {
                position: relative;
                width: 100%;
                height: 416px;
                width: 416px;
                border-radius: 50%;
                background-color: #ffffff;

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 50%;
                }
            }
        }
    }

    > .content {
        margin-top: 24px;

        display: flex;
        flex-direction: column;

        .videos {
            margin-top: 50px;

            > .title {
                position: relative;
                margin-left: 28px;
                margin-bottom: 16px;
                font-family: "Rota-MediumItalic";
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 25px;
                text-transform: uppercase;
                &::before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: -28px;
                    display: flex;
                    margin-right: 8px;
                    height: 1px;
                    width: 20px;
                    background-color: $primary;
                }
            }

            .video-items {
                margin-top: 24px;
            }
        }

        .title {
            font-family: "Rota-BoldItalic";
            font-size: 26px;
            font-style: italic;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 28px;
        }

        .training-materials {
            margin-top: 150px;
            background-color: $primary;
            color: white;
            padding: 50px;
            border-radius: 20px;
            .arrow {
                border-color: white;
                & > svg > g > g {
                    stroke: white;
                }
            }
            .product-training-item {
                flex-grow: 0;
                width: 200px;
                margin-bottom: 24px;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media screen and (max-width: 768px) {
                    width: calc(100vw - 240px);
                }

                .presentation {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .name {
                        font-family: "Rota-MediumItalic";
                        font-size: 20px;
                        font-style: italic;
                        font-weight: 500;
                        letter-spacing: 1px;
                        line-height: 25px;
                        height: 100px;
                    }

                    .logo {
                        margin-top: 26px;
                        height: 100px;
                        width: 100px;
                        border-radius: 50%;
                        background-color: #ffffff;
                        object-fit: contain;
                    }
                }

                .links {
                    .material {
                        margin: 24px 0;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid white;

                        .training-title {
                            font-family: Rota-Regular;
                            font-size: 14px;
                            letter-spacing: 1px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        .technical-sheets {
            margin-top: 150px;
            background-color: $dark;
            color: white;
            padding: 50px;
            border-radius: 20px;
            .arrow {
                border-color: white;
                & > svg > g > g {
                    stroke: white;
                }
            }
            .title {
                font-family: "Rota-BoldItalic";
                font-size: 26px;
                font-style: italic;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 28px;
            }

            .product-technical-item {
                flex-grow: 0;
                width: 200px;
                margin-bottom: 24px;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media screen and (max-width: 768px) {
                    width: calc(100vw - 240px);
                }

                .name {
                    font-family: "Rota-MediumItalic";
                    font-size: 20px;
                    font-style: italic;
                    font-weight: 500;
                    letter-spacing: 1px;
                    line-height: 25px;
                    height: 100px;
                }

                .logo {
                    margin-top: 26px;
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;

                    background-color: #ffffff;
                    object-fit: contain;
                }

                .button {
                    margin-top: 35px;
                }

                .links {
                    .material {
                        margin: 24px 0;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid white;

                        .training-title {
                            font-family: Rota-Regular;
                            font-size: 14px;
                            letter-spacing: 1px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        .introduction {
            margin-top: 30px;
            font-family: Rota-Regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 22px;
        }
    }
}
